import * as React from "react";
import {NavLink} from "react-router-dom";
import {APP_STRINGS, LoadingState, NetworkState, PortalLanguage, UpdateLanguageAction} from "../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showDashboardPage, showApplicationPage, showLanguageSwitch} from "../../branding";
import { APPLICATION_ROUTE, DASHBOARD_ROUTE, REPORTS_ROUTE } from "../../Constants";

export type NavigationComponentProps = {

    language: PortalLanguage,
    logoutState: NetworkState,
    logout: () => void,
    updateLanguageAction: UpdateLanguageAction,
}

const Navigation: React.ComponentType<NavigationComponentProps> = (args: NavigationComponentProps) => {

    const polishActive = args.language === PortalLanguage.Polish;
    const englishActive = args.language === PortalLanguage.English;

    return(
        <nav>
            <div className={'logo is-hidden-mobile'}/>
            <ul className={'top-nav'}>
                {showDashboardPage() &&
                    <>
                        <li>
                            <div className={'is-hidden-mobile'}>
                                <NavLink to={DASHBOARD_ROUTE} exact activeClassName="active">{APP_STRINGS.nav.dashboard}</NavLink>
                            </div>
                            <div className={'mobile-icon'}>
                                <NavLink to={DASHBOARD_ROUTE} exact activeClassName="active">
                                    <FontAwesomeIcon icon="home"/>
                                </NavLink>
                            </div>
                        </li>
                    </>
                }
                <li>
                    <div className={'is-hidden-mobile'}>
                        <NavLink to={REPORTS_ROUTE} exact activeClassName="active">{APP_STRINGS.nav.reports}</NavLink>
                    </div>
                    <div className={'mobile-icon'}>
                        <NavLink to={REPORTS_ROUTE} exact activeClassName="active">
                            <FontAwesomeIcon icon="chart-line"/>
                        </NavLink>
                    </div>
                </li>
                {showApplicationPage() &&
                    <>
                        <li>
                            <div className={'is-hidden-mobile'}>
                                <NavLink to={APPLICATION_ROUTE} exact activeClassName="active">{APP_STRINGS.nav.applications}</NavLink>
                            </div>
                            <div className={'mobile-icon'}>
                                <NavLink to={APPLICATION_ROUTE} exact activeClassName="active">
                                    <FontAwesomeIcon icon="list"/>
                                </NavLink>
                            </div>
                        </li>
                    </>
                }
            </ul>
            <ul className={'bottom-nav'}>
                <li>
                    <div className={'is-hidden-mobile'}>
                        <NavLink to={`/edit-account`} exact activeClassName="active">{APP_STRINGS.nav.editAccount}</NavLink>
                    </div>
                    <div className={'mobile-icon'}>
                        <NavLink to={`/edit-account`} exact activeClassName="active">
                            <FontAwesomeIcon icon="user"/>
                        </NavLink>
                    </div>
                </li>
                <li>
                    <a
                        href="#"
                        onClick={args.logout}
                    >
                        <div className={'is-hidden-mobile'}>
                            {args.logoutState.state === LoadingState.Pending ?
                                <FontAwesomeIcon icon="spinner" pulse={true}/>
                                :
                                APP_STRINGS.nav.logout
                            }
                        </div>
                        <div className={'mobile-icon'}>
                                <FontAwesomeIcon icon="sign-out-alt"/>
                        </div>
                    </a>
                </li>

                {showLanguageSwitch() &&

                    <li className="lang-picker">
                        <span
                            className={`flag flag-pl ${polishActive ? 'active' : ''}`}
                            onClick={() => {
                                if (!polishActive)
                                    args.updateLanguageAction(PortalLanguage.Polish);
                            }}
                        />
                        <span
                            className={`flag flag-gb ${englishActive ? 'active' : ''}`}
                            onClick={() => {
                                if (!englishActive)
                                    args.updateLanguageAction(PortalLanguage.English);
                            }}
                        />
                    </li>
                }
            </ul>
        </nav>
    );
};

export default Navigation;