import * as URI from "urijs";
import { HttpError, HttpSuccess, LoanApiEndpoints } from "../../Types";
import { IHttpRequester } from "../../Interfaces/IHttpRequester";
import { runningInNode } from "../../Utils";
import { ApplicationNeedFile } from "../../ResponseTypes";

export class ClientDocuments {

    private endpoints: LoanApiEndpoints;
    private requester: IHttpRequester;

    constructor(endpoints: LoanApiEndpoints, requester: IHttpRequester) {
        this.endpoints = endpoints;
        this.requester = requester;
    }

    /**
     * To make the SDK smaller a Node friendly form data package has been excluded,
     * so therefore when uploading from Node you'll have to pass something that represents form data.
     *
     * The form data field for the file should be called 'file'.
     *
     * Something like this https://github.com/form-data/form-data
     *
     * @param documentId
     * @param data
     * @param fileName
     * @param additionalHeaders
     */
    public upload(documentId: number, data: any, fileName?: string, additionalHeaders?: any): Promise<ApplicationNeedFile> {

        return new Promise<any>((resolve: (data: any, success: HttpSuccess) => void, reject: (error: HttpError|null) => void) => {

            if (!runningInNode()) {
                data = new FormData();
                data.append('file', data);
            }

            const path = URI.expand(this.endpoints.uploadFile, {
                id: documentId,
            }).valueOf();

            this.requester.postFile(path, data, true, additionalHeaders).then((success: HttpSuccess) => {
                resolve(success.data, success);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }
}