import {StatsChartYCol} from "../../types";
import * as numeral from "numeral";
import {CURRENCY_FORMAT} from "../../locale";
import {CampaignSummaryStats} from "signature-public-api-sdk";

export const FormatData = (stat: CampaignSummaryStats|any, column: StatsChartYCol) => {

    switch (column) {
        case StatsChartYCol.AvgPostCost:
        case StatsChartYCol.AvgAcceptCost:
        case StatsChartYCol.Cost:
        case StatsChartYCol.RPT:
        case StatsChartYCol.EPC:
            return numeral(stat[column]).format(CURRENCY_FORMAT);
        case StatsChartYCol.ClicksAcceptedPercentage:
        case StatsChartYCol.PostsAcceptedPercentage:
        case StatsChartYCol.RedirectRate:
            return numeral(stat[column] * 100).format('0.[00]') + '%';
        case StatsChartYCol.AvgResponseTime:
            return numeral(stat[column]).format('0.[00]') + 's';
        default:
            return stat[column];
    }
};