import * as React from "react";
import {TooltipPayload, TooltipProps} from "recharts";
import {StatsChartYCol} from "../../types";
import {FormatData} from "./FormatData";
import {SYSTEM_DATE_FORMAT} from "../../Constants";
import moment = require("moment");

type Props = TooltipProps | any | {
    chartsValueKey: StatsChartYCol,
};

const DailyPerformanceLineChartTooltip = (args: Props) => {


    if (Array.isArray(args.payload) && args.payload.length > 0) {

        const content = (
            <>
                {args.payload.map((payload: TooltipPayload|any, index: number) => {

                    return (

                        <div
                            key={`tooltip-line-${index}-${payload.value}-${payload.dataKey}`}
                        >
                            {(index === 0 && payload.payload) &&
                                <div
                                    className={'date'}
                                >
                                    {moment(payload.payload.date, SYSTEM_DATE_FORMAT).format('D MMM YYYY')}
                                </div>
                            }
                            <div
                                style={{color: payload.color}}
                            >
                                {FormatData({
                                    [args.chartsValueKey]: payload.value
                                } as any, args.chartsValueKey)}
                            </div>
                        </div>
                    )
                })}
            </>
        );

        return (
            <div
                className={'custom-tooltip campaign-daily-performance-tooltip'}
            >
                <strong>{content}</strong>
            </div>
        );
    }

    return null;
};

export default DailyPerformanceLineChartTooltip;