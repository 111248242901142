import * as React from "react";
import Search from "./Search";
import Summary from "./Summary";
import Campaigns from "./Campaigns";
import {
    APP_STRINGS,
    FetchAllCampaignDailyStatsAction,
    FetchAllCampaignReportsAction,
    FetchCampaignReportsAction,
    LoadingState,
    NetworkState,
    StatsChartYCol
} from "../../types";
import {Moment} from "moment";
import {SYSTEM_DATE_FORMAT} from "../../Constants";
import CampaignPerformancePieChart from "./CampaignPerformancePieChart";
import CampaignDailyPerformanceLineChart from "./CampaignDailyPerformanceLineChart";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment = require("moment");
import { scroller } from 'react-scroll'
import {IsMobile} from "../../utils";
import {AllCampaignDailyStats, CampaignSummaryStats, User} from "signature-public-api-sdk";
import { chartLineColours } from "../../branding";

type Props = {

    user: User,

    overallStats: CampaignSummaryStats,
    campaignSummaryState: NetworkState,

    allCampaignOverallStats: CampaignSummaryStats[],
    allCampaignSummaryState: NetworkState,

    allCampaignDailyStats: AllCampaignDailyStats[],
    allCampaignDailyStatsState: NetworkState,

    fetchCampaignReports: FetchCampaignReportsAction,
    fetchAllCampaignReportsAction: FetchAllCampaignReportsAction,
    fetchAllCampaignDailyStatsAction: FetchAllCampaignDailyStatsAction,
}

type State = {
    start: Moment,
    end: Moment,
    chartsValueKey: StatsChartYCol,
}

const CAMPAIGN_REPORTS_SECTION_ID = 'campaign-reports-section';

class CampaignReports extends React.Component<Props, State> {

    state = {
        start: moment().startOf('month').startOf('day'),
        end: moment().endOf('month').endOf('day'),
        chartsValueKey: StatsChartYCol.Cost,
    };

    componentDidMount() {

        if (!this.isAnythingLoading() && (!this.props.overallStats || !this.props.allCampaignOverallStats || !this.props.allCampaignDailyStats))
            this.doSearches();
    }

    componentWillUpdate(nextProps: Props, nextState: State) {

        if (this.state.chartsValueKey !== nextState.chartsValueKey && IsMobile()) {

            scroller.scrollTo(CAMPAIGN_REPORTS_SECTION_ID, {
                duration: 400,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {

        if (!this.state.start.isSame(prevState.start) || !this.state.end.isSame(prevState.end))
            this.doSearches();
    }

    render() {

        const isAnythingLoading = this.isAnythingLoading();

        return (
            <div id="reports">
                <h1 className={'is-flex'}>
                    {APP_STRINGS.campaigns.pageTitle}
                    <Search
                        start={this.state.start}
                        end={this.state.end}
                        isLoading={isAnythingLoading}
                        onSearch={(start: Moment, end: Moment) => {
                            this.setState({
                                start: start,
                                end: end,
                            });
                        }}
                    />
                </h1>

                <Summary
                    user={this.props.user}
                    summary={this.props.overallStats}
                    chartsValueKey={this.state.chartsValueKey}
                    onRowClicked={(col: StatsChartYCol) => {
                        this.setState({
                            chartsValueKey: col,
                        })
                    }}
                />
                <div
                    id={CAMPAIGN_REPORTS_SECTION_ID}
                    className={'columns is-multiline is-mobile'}
                >
                    <div className={'column is-full'}>
                        <h3 className={'is-flex'}>
                            {APP_STRINGS.campaigns.performanceSummaryLineChartTitle}
                            {this.props.allCampaignDailyStatsState.state === LoadingState.Pending &&
                            <>
                                &nbsp;<FontAwesomeIcon icon={'spinner'} pulse={true} className={'align-self-center'}/>
                            </>
                            }
                        </h3>
                        <div className={`campaign-chart lg`}>
                            <CampaignDailyPerformanceLineChart
                                allCampaignDailyStats={this.props.allCampaignDailyStats}
                                chartsValueKey={this.state.chartsValueKey}
                                startDate={this.state.start}
                                endDate={this.state.end}
                                lineColours={chartLineColours()}
                            />
                        </div>
                    </div>
                    <CampaignPerformancePieChart
                        allCampaignOverallStats={this.props.allCampaignOverallStats}
                        chartsValueKey={this.state.chartsValueKey}
                        allCampaignSummaryState={this.props.allCampaignSummaryState}
                        lineColours={chartLineColours()}
                    />
                </div>
                <Campaigns
                    user={this.props.user}
                    campaigns={this.props.allCampaignOverallStats ? this.props.allCampaignOverallStats : null}
                    allCampaignDailyStatsState={this.props.allCampaignDailyStatsState}
                    lineColours={chartLineColours()}
                />
            </div>
        );
    }

    private isAnythingLoading = () => {

        return (this.props.campaignSummaryState.state === LoadingState.Pending || this.props.allCampaignSummaryState.state === LoadingState.Pending || this.props.allCampaignDailyStatsState.state === LoadingState.Pending);
    };

    private doSearches = () => {
        this.props.fetchCampaignReports(this.state.start.format(SYSTEM_DATE_FORMAT), this.state.end.format(SYSTEM_DATE_FORMAT));
        this.props.fetchAllCampaignReportsAction(this.state.start.format(SYSTEM_DATE_FORMAT), this.state.end.format(SYSTEM_DATE_FORMAT));
        this.props.fetchAllCampaignDailyStatsAction(this.state.start.format(SYSTEM_DATE_FORMAT), this.state.end.format(SYSTEM_DATE_FORMAT));
    };
}

export default CampaignReports;
