import * as React from "react";
import {APP_STRINGS, StatsChartYCol} from "../../types";
import {FormatData} from "./FormatData";
import {CampaignSummaryStats, User} from "signature-public-api-sdk";

type Props = {
    user: User,
    summary: CampaignSummaryStats,
    chartsValueKey: StatsChartYCol,
    onRowClicked: (column: StatsChartYCol) => void;
}

const Summary: React.ComponentType<Props> = (args: Props) => {

    const isBucketAffiliate = args.user.broker.bucketUsedCount;

    return(
        <div
            id="report-summary"
        >
            <h2>{APP_STRINGS.campaigns.summaryTitle}</h2>
                <div
                    className={''}
                >
                <div className={'columns is-mobile is-multiline'}>
                    {isBucketAffiliate ?
                        <>
                            <div className="report-summary-column column is-full-mobile is-half-tablet is-one-third-desktop">
                                {ValueRow(args,APP_STRINGS.stats.payout, StatsChartYCol.Cost)}
                                {ValueRow(args,APP_STRINGS.stats.epc, StatsChartYCol.EPC)}
                                {/*{ValueRow(args,APP_STRINGS.stats.postbackFires, StatsChartYCol.PostbackFires)}*/}
                                {ValueRow(args,APP_STRINGS.stats.clicks, StatsChartYCol.Clicks)}
                            </div>
                        </>
                    : null}
                    {!isBucketAffiliate ?
                        <>
                            <div className="report-summary-column column is-full-mobile is-half-tablet is-one-third-desktop is-auto-widescreen is-auto-fullhd">
                                {/*{ValueRow(args,'Active', 'Yes')}*/}
                                {ValueRow(args,APP_STRINGS.stats.avgPayoutPost, StatsChartYCol.AvgPostCost)}
                                {ValueRow(args,APP_STRINGS.stats.avgPayoutAccept, StatsChartYCol.AvgAcceptCost)}
                                {ValueRow(args,APP_STRINGS.stats.payout, StatsChartYCol.Cost)}
                                {ValueRow(args,APP_STRINGS.stats.rpt, StatsChartYCol.RPT)}
                                {ValueRow(args,APP_STRINGS.stats.epc, StatsChartYCol.EPC)}
                            </div>
                            <div className="report-summary-column column is-full-mobile is-half-tablet is-one-third-desktop is-auto-widescreen is-auto-fullhd">
                                {ValueRow(args,APP_STRINGS.stats.clicks, StatsChartYCol.Clicks)}
                                {ValueRow(args,APP_STRINGS.stats.posts, StatsChartYCol.Posts)}
                                {ValueRow(args,APP_STRINGS.stats.postsAccepted, StatsChartYCol.PostsAccepted)}
                                {ValueRow(args,APP_STRINGS.stats.postsRejected, StatsChartYCol.PostsRejected)}
                                {ValueRow(args,APP_STRINGS.stats.postsErrors, StatsChartYCol.PostsErrors)}
                            </div>
                            <div className="report-summary-column column is-full-mobile is-half-tablet is-one-third-desktop is-auto-widescreen is-auto-fullhd">
                                {ValueRow(args,APP_STRINGS.stats.clicksAccepted, StatsChartYCol.ClicksAcceptedPercentage)}
                                {ValueRow(args,APP_STRINGS.stats.postsAcceptedPercentage, StatsChartYCol.PostsAcceptedPercentage)}
                                {ValueRow(args,APP_STRINGS.stats.avgResponseTime, StatsChartYCol.AvgResponseTime)}
                                {ValueRow(args,APP_STRINGS.stats.redirectRate, StatsChartYCol.RedirectRate)}
                                {ValueRow(args,APP_STRINGS.stats.successfulRedirects, StatsChartYCol.SuccessfulRedirects)}
                            </div>
                        </>
                    : null}
                </div>
            </div>
        </div>
    );
};

const ValueRow = (args: Props, label: string, col: StatsChartYCol) => {

    return (
        <div
            className={`summary-row clickable ${col === args.chartsValueKey ? 'active' : ''}`}
            onClick={() => {
                args.onRowClicked(col);
            }}
        >
            <span className={'label'}>{label}</span>
            <span className={'value'}>{args.summary ? FormatData(args.summary, col) : '-'}</span>
        </div>
    );
};

export default Summary;