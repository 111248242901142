// export const SYSTEM_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const SYSTEM_DATE_FORMAT = 'YYYY-MM-DD';
export const PRETTY_DATE_FORMAT = 'DD/MM/YYYY';
// export const SYSTEM_TIME_FORMAT = 'HH:mm';

export const DEFAULT_REQUEST_HEADERS = {'Content-Type': 'application/json', 'Accept': 'application/json'};


export const GRAPH_CURRENT_LINE_COLOUR_LOAN = '#ff2391';
export const GRAPH_PREVIOUS_LINE_COLOUR_LOAN = '#c80165';

export const GRAPH_CURRENT_LINE_COLOUR_M3 = '#5045AD';
export const GRAPH_PREVIOUS_LINE_COLOUR_M3 = '#00BBB0';

export const GRAPH_CURRENT_LINE_COLOUR_CRED4 = '#e58414';
export const GRAPH_PREVIOUS_LINE_COLOUR_CRED4 = '#000';

export const REPORTS_ROUTE = '/reports';
export const DASHBOARD_ROUTE = '/dashboard';
export const APPLICATION_ROUTE = '/applications';
