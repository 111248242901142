import {IHttpRequester} from "../Interfaces/IHttpRequester";
import {HttpError, HttpSuccess, LoanApiEndpoints} from "../Types";
import {LoginResponse} from "../ResponseTypes";

export class Auth {

    private endpoints: LoanApiEndpoints;
    private requester: IHttpRequester;

    constructor(endpoints: LoanApiEndpoints, requester: IHttpRequester) {
        this.endpoints = endpoints;
        this.requester = requester;
    }

    /**
     * Generate a token for access to our public API.
     * @param username
     * @param password
     */
    public login(username: string, password: string): Promise<LoginResponse> {

        return new Promise<LoginResponse>((resolve: (data: LoginResponse) => void, reject: (error: HttpError|null) => void) => {

            this.requester.setToken(null);

            this.requester.post(this.endpoints.login, {username: username, password: password}, false).then((success: HttpSuccess) => {

                if (success.data.token && success.data.user) {
                    resolve({
                        token: success.data.token,
                        user: success.data.user,
                    });
                }
                else
                    reject(success as any);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }

    /**
     * Invalidate the current session.
     */
    public logout(): Promise<null> {

        return new Promise<null>((resolve: (data: null) => void, reject: (error: HttpError|null) => void) => {
            this.requester.delete(this.endpoints.logout).then((success: HttpSuccess) => {
                resolve(null);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }
}