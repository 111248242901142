export const EN = {
    login: {
        title: 'Login to your affiliate portal',
        usernamePlaceholder: 'Username',
        passwordPlaceholder: 'Password',
        loginButtonLabel: 'Login',

        forgotPasswordTitle: 'Forgotten your password?',
        forgotPasswordContent: 'No worries,',
        forgotPasswordLink: 'click here to reset your password',
    },
    forgotPassword: {
        title: 'Forgotten Password?',
        usernamePlaceholder: 'Username',
        resetButtonLabel: 'Reset',
    },
    nav: {
        dashboard: 'DASHBOARD',
        reports: 'REPORTS',
        applications: 'APPLICATIONS',
        editAccount: 'MY ACCOUNT',
        logout: 'LOGOUT',
    },
    dashboard: {
        dashboardTitle: 'Dashboard',
        overallChartTitle: 'Overall Monthly Earnings',
        currentPeriod: 'Current Period',
        previousPeriod: 'Previous Period',

        periodDropdown: {
            today: 'Today',
            yesterday: 'Yesterday',
            last7Days: 'Last 7 Days',
            lastMonth: 'Last Month',
            last3Months: 'Last 3 Months',
            last6Months: 'Last 6 Months',
            lastYear: 'Last Year',
        },

        graphTooltip: {
            payout: 'Payout',
            clicks: 'Clicks',
            epc: 'EPC',
            rpt: 'RPT',
            postsAccepted: 'Posts Accepted',
            leads: 'Leads',
            postbackFires: 'Sales',
        },
    },
    campaigns: {
        pageTitle: 'Reports',
        summaryTitle: 'Campaign Summary',
        performanceSummaryPieChartTitle: 'Campaign Performance Summary',
        performanceSummaryLineChartTitle: 'Campaign Performance',

        noSubId: 'No SubId',
        noSubIdName: 'No SubId Name',

        searchBtnLabel: 'Search',

        campaigns: 'Campaigns',
        subCampaigns: 'Sub Campaigns',

        table: {
            title: 'All Campaigns',

            campaign: 'ID',
            active: 'Active',
            payoutPerPost: 'Payout / Post',
            payoutPerAccept: 'Payout / Accept',
            payout: 'Payout',
            clicks: 'Clicks',
            posts: 'Posts',
            postsAccepted: 'Post Accepts',
            postsRejected: 'Post Rejects',
            postsErrors: 'Post Errors',
            postbackFires: 'Sales',
            postsAcceptedPercentage: 'Post Accepts %',
            clicksAcceptedPercentage: 'Click  Accepts %',
            successfulRedirects: 'Success Redirect',
            redirectRatePercentage: 'Redirect Rate',

            noName: '- No Name -',
        }

    },
    applications: {
        title: 'Applications',
        table: {
            appId: 'Application Id',
            created: 'Created Date',
            leadApplicant: 'Lead Applicant Name',
            appType: 'Application Type',
            loanType: 'Loan Type',
            stage: 'Stage',
            loanAmount: 'Loan Amount'
        }
    },
    charts: {
        date: 'Date',
    },
    stats: {
        clicks: 'Clicks',
        clicksAccepted: 'Clicks Accepted',
        clicksAcceptedPercentage: 'Clicks Accepted Percentage',
        epc: 'EPC', //Earning Per Click
        rpt: 'RPT', //Revenue Per Transaction
        conversions: 'Conversions',

        payout: 'Payout',
        avgPayoutPost: 'Avg. Payout / Post',
        avgPayoutAccept: 'Avg. Payout / Accept',

        posts: 'Posts',
        postsAccepted: 'Posts Accepted',
        postsAcceptedPercentage: 'Posts Accepted Percentage',
        postsRejected: 'Posts Rejected',
        postsErrors: 'Posts Errors',
        postbackFires: 'Sales',

        avgResponseTime: 'Avg. Response Time',
        totalResponseTime: 'Total Response Time',
        postsWithResponseTime: 'Posts With Response Time',

        redirectRate: 'Redirect Rate',
        redirectRatePercentage: 'Redirect Rate Percentage',
        successfulRedirects: 'Successful Redirects',

        payoutYearToDate: 'Ytd',
        totalLeads: 'Total Leads',
        noData: 'No data',
        increase: 'increase',
        decrease: 'decrease',
    },
    myAccount: {
        title: 'My Account',
        companyName: 'Company Name',
        username: 'Username',
        updatePasswordTitle: 'Update Password',
        updatePasswordSubmitButton: 'Update Password',
        updatePasswordSuccessSubmitButton: 'Password Updated!',


        currentPassword: 'Current Password',
        newPassword: 'New Password',
        confirmNewPassword: 'Confirm New Password',

        enterCurrentPassword: 'Please enter your current password',
        newPasswordsMustMatch: 'New passwords must match',
        newPasswordsMinLength: 'New passwords must be at least 6 characters long',
        failedPasswordUpdate: 'Failed to update password.',

    }
};