import * as React from "react";
import * as numeral from "numeral";
import { get } from "lodash";
import {APP_STRINGS, LoadingState, NetworkState, StatsChartYCol} from "../../types";
import {CURRENCY_FORMAT} from "../../locale";
import moment = require("moment");
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {TimePeriodComparisonStatsResponse, User} from "signature-public-api-sdk";

type Props = {
    user: User,
    summary: TimePeriodComparisonStatsResponse,
    summaryState: NetworkState,

    onStatTileClicked: (stat: StatsChartYCol) => void,
    graphYColumnKey: StatsChartYCol,
}

const POSITIVE_CLASS = 'positive';
const NEUTRAL_CLASS = 'neutral';
const NEGATIVE_CLASS = 'negative';

export default class Summary extends React.Component<Props> {

    render() {

        const args = this.props;

        let clicksPositive = NEUTRAL_CLASS;
        let epcPositive = NEUTRAL_CLASS;
        let rptPositive = NEUTRAL_CLASS;
        let conversionsPositive = NEUTRAL_CLASS;
        let payoutPositive = NEUTRAL_CLASS;
        let payoutYearPositive = NEUTRAL_CLASS;
        let leadsPositive = NEUTRAL_CLASS;
        let firesPositive = NEUTRAL_CLASS;

        if (args.summary) {

            if (args.summary.current.clicks < args.summary.previous.clicks)
                clicksPositive = NEGATIVE_CLASS;
            else if (args.summary.current.clicks > args.summary.previous.clicks)
                clicksPositive = POSITIVE_CLASS;

            if (args.summary.current.epc < args.summary.previous.epc)
                epcPositive = NEGATIVE_CLASS;
            else if (args.summary.current.epc > args.summary.previous.epc)
                epcPositive = POSITIVE_CLASS;

            if (args.summary.current.rpt < args.summary.previous.rpt)
                rptPositive = NEGATIVE_CLASS;
            else if (args.summary.current.rpt > args.summary.previous.rpt)
                rptPositive = POSITIVE_CLASS;

            if (args.summary.current.postsAccepted < args.summary.previous.postsAccepted)
                conversionsPositive = NEGATIVE_CLASS;
            else if (args.summary.current.postsAccepted > args.summary.previous.postsAccepted)
                conversionsPositive = POSITIVE_CLASS;

            if (args.summary.current.cost < args.summary.previous.cost)
                payoutPositive = NEGATIVE_CLASS;
            else if (args.summary.current.cost > args.summary.previous.cost)
                payoutPositive = POSITIVE_CLASS;

            if (args.summary.year.current < args.summary.year.previous)
                payoutYearPositive = NEGATIVE_CLASS;
            else if (args.summary.year.current > args.summary.year.previous)
                payoutYearPositive = POSITIVE_CLASS;

            if (args.summary.current.posts < args.summary.previous.posts)
                leadsPositive = NEGATIVE_CLASS;
            else if (args.summary.current.posts > args.summary.previous.posts)
                leadsPositive = POSITIVE_CLASS;

            if (args.summary.current.postbackFires < args.summary.previous.postbackFires)
                firesPositive = NEGATIVE_CLASS;
            else if (args.summary.current.postbackFires > args.summary.previous.postbackFires)
                firesPositive = POSITIVE_CLASS;
        }

        const isBucketAffiliate = args.user.broker.bucketUsedCount;

        return (
            <div
                id={'dashboard-summary'}
                className={'container is-fluid'}
            >
                {/* <div>Date drop down</div> */}

                <div className={'summary-tiles columns is-multiline is-mobile'}>
                    {this.tile(args.summary, StatsChartYCol.Clicks, APP_STRINGS.stats.clicks, 'current.clicks', 'previous.clicks', 'diff.clicks', clicksPositive)}
                    {this.tile(args.summary, StatsChartYCol.EPC, APP_STRINGS.stats.epc, 'current.epc', 'previous.epc', 'diff.epc', epcPositive, true)}


                    {/*{isBucketAffiliate &&*/}
                        {/*this.tile(args.summary, StatsChartYCol.PostbackFires, APP_STRINGS.stats.postbackFires, 'current.postbackFires', 'previous.postbackFires', 'diff.postbackFires', firesPositive)*/}
                    {/*}*/}

                    {!isBucketAffiliate &&
                        this.tile(args.summary, StatsChartYCol.RPT, APP_STRINGS.stats.rpt, 'current.rpt', 'previous.rpt', 'diff.rpt', rptPositive, true)
                    }


                    {!isBucketAffiliate &&
                       this.tile(args.summary, StatsChartYCol.PostsAccepted, APP_STRINGS.stats.conversions, 'current.postsAccepted', 'previous.postsAccepted',     'diff.postsAccepted', conversionsPositive)
                    }
                    {this.tile(args.summary, StatsChartYCol.Cost, APP_STRINGS.stats.payout, 'current.cost', 'previous.cost', 'diff.cost', payoutPositive, true)}
                    {this.tile(args.summary, null, `${APP_STRINGS.stats.payout} (${moment().year()}) ${APP_STRINGS.stats.payoutYearToDate}`, 'year.current', 'year.previous', 'year.diff', payoutYearPositive, true)}


                    {!isBucketAffiliate &&
                        this.tile(args.summary, StatsChartYCol.Posts, APP_STRINGS.stats.totalLeads, 'current.posts', 'previous.posts', 'diff.posts', leadsPositive)
                    }
                </div>
            </div>
        )
    }

    private tile = (summary: TimePeriodComparisonStatsResponse, stat: StatsChartYCol, header: string, dataKeyPath: string, prevDataKeyPath: string, diffDataKeyPath: string, tileClass: string, currency?: boolean) => {

        const current = summary ? get(summary, dataKeyPath) : null;
        const prev = summary ? get(summary, prevDataKeyPath) : null;
        const diff = summary ? get(summary, diffDataKeyPath) : null;

        const noPrevData = summary && prev === 0;
        const noChange = get(summary, diffDataKeyPath) === 0;

        return (
            <div
                className={`summary-tile ${tileClass} ${stat === this.props.graphYColumnKey ? 'active' : ''} column is-one-third-tablet is-half-mobile is-one-quarter-desktop is-auto-widescreen is-auto-fullhd`}
                onClick={() => {
                    if (stat)
                        this.props.onStatTileClicked(stat);
                }}
            >
                <div className={'header'}>
                    {header}
                </div>
                <div className={'current'}>
                    {this.props.summaryState.state === LoadingState.Pending &&
                        <FontAwesomeIcon icon="spinner" pulse={true} />
                    }
                    {(this.props.summaryState.state !== LoadingState.Pending && summary) ? currency === true ? numeral(current).format(CURRENCY_FORMAT) : numeral(current).format('0.[00]'): null}
                </div>
                <div className={`previous ${noPrevData ? 'no-data' : ''}`}>
                    {(summary && !noPrevData && !noChange) &&
                        <>
                            {numeral(Math.abs(diff)).format(currency === true ? CURRENCY_FORMAT : '0.[00]')}
                            &nbsp;
                            {tileClass === POSITIVE_CLASS && APP_STRINGS.stats.increase}
                            {tileClass === NEGATIVE_CLASS && APP_STRINGS.stats.decrease}
                        </>
                    }
                    {noPrevData &&
                        APP_STRINGS.stats.noData
                    }
                    {noChange &&
                        '-'
                    }
                </div>
            </div>
        )
    };
}
