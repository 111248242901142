export const PL = {
    login: {
        title: 'Zaloguj się do panelu partnerskiego',
        usernamePlaceholder: 'Nazwa użytkownika',
        passwordPlaceholder: 'Hasło',
        loginButtonLabel: 'Zaloguj się',

        forgotPasswordTitle: 'Zapomniałeś hasła?',
        forgotPasswordContent: 'Nie ma problemu,',
        forgotPasswordLink: 'kliknij tutaj by zresetować swoje hasło',
    },
    forgotPassword: {
        title: 'Zapomniałeś hasła?',
        usernamePlaceholder: 'Nazwa użytkownika',
        resetButtonLabel: 'Zresetuj hasło',
    },
    nav: {
        dashboard: 'PANEL PARTNERSKI',
        reports: 'RAPORTY',
        applications: 'APPLICATIONS',
        editAccount: 'MOJE KONTO',
        logout: 'WYLOGUJ SIĘ',
    },
    dashboard: {
        dashboardTitle: 'Panel partnerski',
        overallChartTitle: 'Całkowite miesięczne zarobki',
        currentPeriod: 'Obecny Okres',
        previousPeriod: 'Poprzedni Okres',

        periodDropdown: {
            today: 'Dzisiaj',
            yesterday: 'Wczoraj',
            last7Days: 'Ostatnie 7 dni',
            lastMonth: 'Ostatni miesiąc',
            last3Months: 'Ostatnie 3 miesiące',
            last6Months: 'Ostatnie 6 miesięcy',
            lastYear: 'Ostatni rok',
        },

        graphTooltip: {
            payout: 'Wypłata',
            clicks: 'Kliknięcia',
            epc: 'EPC',
            rpt: 'RPT',
            postsAccepted: 'Posty Zaakcepotwane',
            leads: 'Leads',
            postbackFires: 'Sprzedaż',
        },
    },
    campaigns: {
        pageTitle: 'Raporty',
        summaryTitle: 'Podusmowanie kampanii',
        performanceSummaryPieChartTitle: 'Podsumowanie skuteczności kampanii',
        performanceSummaryLineChartTitle: 'Skuteczność kampanii',

        noSubId: 'brak SubId',
        noSubIdName: 'brak SubId nazwy',

        searchBtnLabel: 'Wyszukaj',

        campaigns: 'Kampanie',
        subCampaigns: 'Sub Kampanie',

        table: {
            title: 'Wszystkie Kampanie',

            campaign: 'ID',
            active: 'Aktywne',
            payoutPerPost: 'Wypłata / Post',
            payoutPerAccept: 'Wypłata / Zaakceptowane',
            payout: 'Wypłata',
            clicks: 'Kliknięcia',
            posts: 'Posty',
            postsAccepted: 'Posty Zaakceptowane',
            postsRejected: 'Posty Odrzucone',
            postsErrors: 'Błędy w Postach',
            postbackFires: 'Sprzedaż',
            postsAcceptedPercentage: 'Posty Zaakceptowane %',
            clicksAcceptedPercentage: 'Kliknięcia  Zaakceptowane %',
            successfulRedirects: 'Skuteczne Przekierowania',
            redirectRatePercentage: 'Wskaźnik przekierowań',

            noName: '- Brak imienia -',
        }

    },
    applications: {
        title: 'Applications',
        table: {
            appId: 'Application Id',
            created: 'Created Date',
            leadApplicant: 'Lead Applicant Name',
            appType: 'Application Type',
            loanType: 'Loan Type',
            stage: 'Stage',
            loanAmount: 'Loan Amount'
        }
    },
    charts: {
        date: 'Data',
    },
    stats: {
        clicks: 'Kliknięcia',
        clicksAccepted: 'Kliknięcia Zaakceptowane',
        clicksAcceptedPercentage: 'Procent Zaakceptowanych Kliknięć',
        epc: 'EPC', //Earning Per Click
        rpt: 'RPT', //Revenue Per Transaction
        conversions: 'Konwersje',

        payout: 'Wypłata',
        avgPayoutPost: 'Średnia wypłata / Post',
        avgPayoutAccept: 'Śriednia wypłata/ Accept',

        posts: 'Posty',
        postsAccepted: 'Posty Zaakceptowane',
        postsAcceptedPercentage: 'Procent Zaakceptowanych Postów',
        postsRejected: 'Posty Odrzucone',
        postsErrors: 'Post Errors',
        postbackFires: 'Sprzedaż',

        avgResponseTime: 'Średni Czas Odpowiedzi',
        totalResponseTime: 'Całkowity Czas Odpowiedzi',
        postsWithResponseTime: 'Post z Czasem Odpowiedzi',

        redirectRate: 'Wskaźnik Przekierowań',
        redirectRatePercentage: 'Wskaźnik Przekierowań w Procentach',
        successfulRedirects: 'Skuteczne Przekierowania',

        payoutYearToDate: 'rok do daty',
        totalLeads: 'Wszystkie Leady',
        noData: 'Brak daty',
        increase: 'Wzrost',
        decrease: 'Spadek',
    },
    myAccount: {
        title: 'Moje Konto',
        companyName: 'Nazwa Firmy',
        username: 'Nazwa Użytkownika',
        updatePasswordTitle: 'Zaktualizuj Hasło',
        updatePasswordSubmitButton: 'Zaktualizuj Hasło',
        updatePasswordSuccessSubmitButton: 'Hasło Zaktualizowane!',


        currentPassword: 'Obecne Hasło',
        newPassword: 'Nowe Hasło',
        confirmNewPassword: 'Potwierdź Nowe Hasło',

        enterCurrentPassword: 'Proszę wprowadź swoje aktualne hasło',
        newPasswordsMustMatch: 'Nowe hasła muszą się zgadzać',
        newPasswordsMinLength: 'Nowe hasła muszą mieć co najmniej 6 znaków',
        failedPasswordUpdate: 'Nie udało się zaktualizować hasła.',
    }
};