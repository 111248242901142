import * as React from "react";
import Summary from "./Summary";
import {
    APP_STRINGS,
    FetchOverallStatsAction,
    FetchSummaryAction,
    LoadingState,
    NetworkState,
    OverallStatsSpan,
    SetDashboardSpanAmount,
    StatsChartYCol,
    SummaryTimeSpan
} from "../../types";
import {SYSTEM_DATE_FORMAT} from "../../Constants";
import {scroller} from 'react-scroll'
import {Moment} from "moment";
import TimeSpanDropdown from "./TimeSpanDropdown";
import moment = require("moment");
import {debounce} from "debounce";
import * as NumericInput from "react-numeric-input";
import {TimeOverallStatsResponse, TimePeriodComparisonStatsResponse, User} from "signature-public-api-sdk";
import {chartCurrentPeriodColour, chartPreviousPeriodColour} from "../../branding";
import Graph from "./Graph";

type Props = {

    user: User,

    overallStatsSpanAmount: number,
    setDashboardSpanAmount: SetDashboardSpanAmount,

    summary: TimePeriodComparisonStatsResponse,
    stats: TimeOverallStatsResponse,

    summaryState: NetworkState,
    overallStatsState: NetworkState,

    fetchSummary: FetchSummaryAction,
    fetchStats: FetchOverallStatsAction,
}

type State = {

    stats: TimeOverallStatsResponse,

    summaryTimeCustomDates: string[],

    overallStatsSpan: OverallStatsSpan,

    overallStatsDates: string[],

    graphYColumnKey: StatsChartYCol,
}

const DASHBOARD_GRAPH = 'dashboard-graph';

class Dashboard extends React.Component<Props, State> {

    state = {
        stats: this.props.stats,
        summaryTimeCustomDates: TimeSpanDropdown.getDates(SummaryTimeSpan.SevenDays).map((date: Moment) => {return date.format(SYSTEM_DATE_FORMAT)}),

        overallStatsSpan: OverallStatsSpan.Months,
        overallStatsDates: [],

        graphYColumnKey: StatsChartYCol.Cost,
    };

    private debounceSearchOverallStats = null;

    componentDidMount() {

        this.debounceSearchOverallStats = debounce(this.searchOverallStats, 1000);

        if (this.props.summaryState.state !== LoadingState.Pending && !this.props.summary)
            this.searchCustomerTimeSpan();

        if (this.props.overallStatsState.state !== LoadingState.Pending && !this.props.stats)
            this.searchOverallStats();
    }

    componentWillUnmount() {
        this.debounceSearchOverallStats = null;
    }

    componentWillUpdate(nextProps: Props, nextState: State) {

        if (this.state.graphYColumnKey !== nextState.graphYColumnKey) {

            scroller.scrollTo(DASHBOARD_GRAPH, {
                duration: 400,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.overallStatsState.state === LoadingState.Pending && nextProps.overallStatsState.state === LoadingState.Nothing) {
            this.setState({
                stats: nextProps.stats,
            });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {

        if (prevProps.overallStatsSpanAmount !== this.props.overallStatsSpanAmount) {
            this.debounceSearchOverallStats();
            // this.searchOverallStats();
        }
    }

    render() {
        const versusFormat = 'DD MMM YYYY';

        return (
            <div id="dashboard">
                <h1 className="is-flex align-items-center">
                    {APP_STRINGS.dashboard.dashboardTitle}

                    <TimeSpanDropdown
                        onChange={(dates: Moment[]) => {
                            this.setState({
                                    summaryTimeCustomDates: dates.map((date: Moment) => {
                                        return date.format(SYSTEM_DATE_FORMAT);
                                    }),
                                },
                                () => {
                                    this.searchCustomerTimeSpan();
                                }
                            )
                        }}
                    />
                </h1>
                <Summary
                    user={this.props.user}
                    summary={this.props.summary}
                    summaryState={this.props.summaryState}
                    graphYColumnKey={this.state.graphYColumnKey}
                    onStatTileClicked={(stat: StatsChartYCol) => {
                        this.setState({
                            graphYColumnKey: stat,
                        });
                    }}
                />
                <h2 className={'is-flex align-items-center'}>
                    {APP_STRINGS.dashboard.overallChartTitle}

                    <div className="field overall-stats-months">
                        <div className="control">
                            <NumericInput
                                min={1}
                                max={24}
                                value={this.props.overallStatsSpanAmount}
                                className="input"
                                mobile={true}
                                onChange={(valueAsNumber: number) => {
                                    this.props.setDashboardSpanAmount(valueAsNumber);

                                    this.setState({
                                        stats: null,
                                    });
                                }}
                                disabled={this.props.overallStatsState.state === LoadingState.Pending}
                            />
                        </div>
                    </div>
                </h2>

                <div className={'graph-legend is-flex'}>
                    <div
                        className={'key'}
                        title={`${moment(this.state.overallStatsDates[0], SYSTEM_DATE_FORMAT).format(versusFormat)} -> ${moment(this.state.overallStatsDates[1], SYSTEM_DATE_FORMAT).format(versusFormat)}`}
                        style={{
                            color: chartCurrentPeriodColour(),
                        }}
                    >
                        {APP_STRINGS.dashboard.currentPeriod}
                        <div
                            className={'chart-line'}
                            style={{
                                borderColor: chartCurrentPeriodColour()
                            }}
                        />
                    </div>
                    <div
                        className={'key dashed'}
                        title={`${moment(this.state.overallStatsDates[2], SYSTEM_DATE_FORMAT).format(versusFormat)} -> ${moment(this.state.overallStatsDates[3], SYSTEM_DATE_FORMAT).format(versusFormat)}`}
                        style={{
                            color: chartPreviousPeriodColour(),
                        }}
                    >
                        {APP_STRINGS.dashboard.previousPeriod}
                        <div
                            className={'chart-line'}
                            style={{
                                borderColor: chartPreviousPeriodColour()
                            }}
                        />
                    </div>
                </div>

                <div id={DASHBOARD_GRAPH} className="graph">
                    <Graph
                        user={this.props.user}
                        stats={this.state.stats}
                        overallStatsSpan={this.state.overallStatsSpan}
                        overallStatsSpanAmount={this.props.overallStatsSpanAmount}
                        lineKey={this.state.graphYColumnKey}
                    />
                </div>
            </div>
        );
    }

    private option = (value: any, label: string) => {

        return (
            <option
                key={`${value}-${label}`}
                value={value}
            >
                {label}
            </option>
        )
    };

    private searchCustomerTimeSpan = () => {
        this.fetchSummary(this.state.summaryTimeCustomDates[0], this.state.summaryTimeCustomDates[1], this.state.summaryTimeCustomDates[2], this.state.summaryTimeCustomDates[3]);
    };

    private fetchSummary = (startDate: string, endDate: string, startDate2: string, endDate2: string) => {
        this.props.fetchSummary(startDate, endDate, startDate2, endDate2);

        this.setState({
            summaryTimeCustomDates: [
                startDate,
                endDate,
                startDate2,
                endDate2,
            ],
        });
    };

    private searchOverallStats = () => {

        const count = this.props.overallStatsSpanAmount;

        if (this.state.overallStatsSpan === OverallStatsSpan.Months) {

            const start = moment().startOf('month').subtract((count - 1), 'months');
            const end  = moment().endOf('month');

            const start2 = start.clone().startOf('month').subtract(count, 'months');
            const end2 = start.clone().subtract(1, 'day');

            this.fetchStats(start.format(SYSTEM_DATE_FORMAT), end.format(SYSTEM_DATE_FORMAT), start2.format(SYSTEM_DATE_FORMAT), end2.format(SYSTEM_DATE_FORMAT));
        }
    };

    private fetchStats = (startDate: string, endDate: string, startDate2: string, endDate2: string) => {

        this.props.fetchStats(startDate, endDate, startDate2, endDate2);

        this.setState({
            overallStatsDates: [
                startDate,
                endDate,
                startDate2,
                endDate2,
            ],
        });
    }
}

export default Dashboard;
