import * as React from "react";
import {SyntheticEvent} from "react";
import {Link, Redirect} from "react-router-dom";
import {APP_STRINGS, LoadingState, LoginAction, NetworkState, PortalLanguage, UpdateLanguageAction} from "../types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {primaryButtonClass, showLanguageSwitch, successfulLoginRedirect} from "../branding";

type Props = {
    loggedIn: boolean,
    login: LoginAction,
    loginState: NetworkState,

    language: PortalLanguage,
    updateLanguageAction: UpdateLanguageAction,
}

type State = {
    username: string,
    password: string
}

export default class Login extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            username: '',
            password: ''
        };
    }

    render() {

        if (this.props.loggedIn) {
            return (
                <Redirect
                    to={{
                        pathname: successfulLoginRedirect(),
                    }}
                />
            )
        }

        const polishActive = this.props.language === PortalLanguage.Polish;
        const englishActive = this.props.language === PortalLanguage.English;

        return (
            <div id="login">
                <div className={'logo'}/>
                <h1>{APP_STRINGS.login.title}</h1>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <div className="field">
                        <div className="control has-icons-left">
                            <input
                                className="input"
                                type="text"
                                placeholder={APP_STRINGS.login.usernamePlaceholder}
                                value={this.state.username}
                                onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                                    this.setState({
                                        username: e.currentTarget.value
                                    })
                                }}
                                disabled={this.props.loginState.state === LoadingState.Pending}
                            />
                            <span className="icon is-left">
                          <FontAwesomeIcon icon={'envelope'}/>
                        </span>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control has-icons-left">
                            <input
                                className="input"
                                type="password"
                                placeholder={APP_STRINGS.login.passwordPlaceholder}
                                value={this.state.password}
                                onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                                    this.setState({
                                        password: e.currentTarget.value
                                    })
                                }}
                                disabled={this.props.loginState.state === LoadingState.Pending}
                            />
                            <span className="icon is-left">
                          <FontAwesomeIcon icon={'lock'}/>
                        </span>
                        </div>
                    </div>

                    {this.props.loginState.state === LoadingState.Error &&
                        <div>
                            Login Failed.
                        </div>
                    }
                    <div className={'controls'}>
                        {showLanguageSwitch() &&

                            <div className={'lang-picker'}>
                                <span
                                    className={`flag flag-pl ${polishActive ? 'active' : ''}`}
                                    onClick={() => {
                                        if (!polishActive)
                                            this.props.updateLanguageAction(PortalLanguage.Polish);
                                    }}
                                />
                                <span
                                    className={`flag flag-gb ${englishActive ? 'active' : ''}`}
                                    onClick={() => {
                                        if (!englishActive)
                                            this.props.updateLanguageAction(PortalLanguage.English);
                                    }}
                                />
                            </div>
                        }

                        <button
                            type="submit"
                            className={`button ${primaryButtonClass()} is-pulled-right ${this.props.loginState.state === LoadingState.Pending ? 'is-loading' : ''}`}
                            onClick={() => {
                                this.props.login(this.state.username, this.state.password);
                            }}
                        >
                            {APP_STRINGS.login.loginButtonLabel}
                        </button>
                    </div>
                </form>

                {/*<div className="forgotten">*/}
                    {/*<h2>{APP_STRINGS.login.forgotPasswordTitle}</h2>*/}
                    {/*<p>{APP_STRINGS.login.forgotPasswordContent} <Link to="/forgotten-password">{APP_STRINGS.login.forgotPasswordLink}</Link></p>*/}
                {/*</div>*/}
            </div>
        )
    }
}
