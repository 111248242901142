import * as React from "react";
import {TooltipProps} from "recharts";
import moment = require("moment");
import {SYSTEM_DATE_FORMAT} from "../../Constants";
import {CURRENCY_FORMAT} from "../../locale";
import {DailyStat} from "signature-public-api-sdk";
import {chartCurrentPeriodColour, chartPreviousPeriodColour} from "../../branding";
import {APP_STRINGS} from "../../types";

type Props = TooltipProps|any;

const CustomComparisonTooltip = (args: Props) => {

    if (Array.isArray(args.payload) && args.payload.length == 2) {

        const first = args.payload[0].payload.dailyStatCurrent as DailyStat;
        const second = args.payload[1].payload.dailyStatPrevious as DailyStat;

        const isBucketAffiliate = args.payload[1].payload.user.broker.bucketUsedCount;

        if (first || second) {

            return (
                <div className={'custom-tooltip overall-stats-tooltip'}>
                    <div className={'dates'}>
                        {first &&
                            <div
                                className={'date'}
                                style={{color: chartCurrentPeriodColour()}}
                            >
                                {moment(first.date, SYSTEM_DATE_FORMAT).format('D MMM YYYY')}
                            </div>
                        }

                        {second &&
                            <div
                                className={'date2'}
                                style={{color: chartPreviousPeriodColour()}}
                            >
                                {moment(second.date, SYSTEM_DATE_FORMAT).format('D MMM YYYY')}
                            </div>
                        }
                    </div>
                    <div className={'stats'}>
                        {stat(APP_STRINGS.dashboard.graphTooltip.payout, first ? numeral(first.cost).format(CURRENCY_FORMAT) : null, second ? numeral(second.cost).format(CURRENCY_FORMAT) : null)}
                        {stat(APP_STRINGS.dashboard.graphTooltip.clicks, first ? first.clicks : null, second ? second.clicks : null)}
                        {stat(APP_STRINGS.dashboard.graphTooltip.epc, first ? numeral(first.epc).format('0.[00]') : null, second ? numeral(second.epc).format('0.[00]') : null)}

                        {/*{isBucketAffiliate &&*/}
                            {/*stat(APP_STRINGS.dashboard.graphTooltip.postbackFires, first ? first.postbackFires : null, second ? second.postbackFires : null)*/}
                        {/*}*/}

                        {!isBucketAffiliate &&
                            <>
                                {stat(APP_STRINGS.dashboard.graphTooltip.rpt, first ? numeral(first.rpt).format('0.[00]') : null, second ? numeral(second.rpt).format('0.[00]') : null)}
                                {stat(APP_STRINGS.dashboard.graphTooltip.postsAccepted, first ? first.postsAccepted : null, second ? second.postsAccepted : null)}
                                {stat(APP_STRINGS.dashboard.graphTooltip.leads, first ? first.posts : null, second ? second.posts : null)}
                            </>
                        }
                    </div>
                </div>
            );
        }
    }

    return null;
};

const stat = (label: string, value1: any, value2?: any) => {

    return (
        <div className={'stat'}>
            <div className={'header'}>
                {label}
            </div>
            {value1 &&
                <div className={'value'}>
                    <div style={{
                        color: chartCurrentPeriodColour(),
                    }}>
                        {value1}
                    </div>
                </div>
            }
            {value2 &&
                <div className={'value'}>

                    <div style={{
                        color: chartPreviousPeriodColour(),
                    }}>
                        {value2}
                    </div>
                </div>
            }
        </div>
    )
};

export default CustomComparisonTooltip;