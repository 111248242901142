import {IHttpRequester} from "../../Interfaces/IHttpRequester";
import {HttpError, HttpSuccess, LoanApiEndpoints} from "../../Types";
import {TimeOverallStatsResponse, TimePeriodComparisonStatsResponse} from "../../ResponseTypes";
import * as URI from "urijs";


export class AffiliateDashboard {

    private endpoints: LoanApiEndpoints;
    private requester: IHttpRequester;

    constructor(endpoints: LoanApiEndpoints, requester: IHttpRequester) {
        this.endpoints = endpoints;
        this.requester = requester;
    }

    public timeComparisonStats(startDate: string, endDate?: string, startDate2?: string, endDate2?: string): Promise<TimePeriodComparisonStatsResponse> {

        return new Promise<TimePeriodComparisonStatsResponse>((resolve: (data: TimePeriodComparisonStatsResponse) => void, reject: (error: HttpError|null) => void) => {

            const url = new URI(this.endpoints.timeComparisonStats);

            if (startDate)
                url.addQuery('startDate', startDate);

            if (endDate)
                url.addQuery('endDate', endDate);

            if (startDate2)
                url.addQuery('startDate2', startDate2);

            if (endDate2)
                url.addQuery('endDate2', endDate2);

            this.requester.get(url.valueOf()).then((success: HttpSuccess) => {
                if (success.data.current && success.data.previous && success.data.diff)
                    resolve(success.data);
                else
                    reject(null);
            }).catch((error: HttpError) => {
                reject(error)
            });

        });
    }

    public timeOverallStats(startDate: string, endDate?: string, startDate2?: string, endDate2?: string): Promise<TimeOverallStatsResponse> {

        return new Promise<TimeOverallStatsResponse>((resolve: (data: TimeOverallStatsResponse) => void, reject: (error: HttpError|null) => void) => {

            const url = new URI(this.endpoints.overallComparisonStats);

            if (startDate)
                url.addQuery('startDate', startDate);

            if (endDate)
                url.addQuery('endDate', endDate);

            if (startDate2)
                url.addQuery('startDate2', startDate2);

            if (endDate2)
                url.addQuery('endDate2', endDate2);

            this.requester.get(url.valueOf()).then((success: HttpSuccess) => {
                if (Array.isArray(success.data.current) && Array.isArray(success.data.previous))
                    resolve(success.data);
                else
                    reject(null);
            }).catch((error: HttpError) => {
                reject(error)
            });

        });
    }

    public timeOverallStatsSpan(months: number): Promise<TimeOverallStatsResponse> {

        return new Promise<TimeOverallStatsResponse>((resolve: (data: TimeOverallStatsResponse) => void, reject: (error: HttpError|null) => void) => {

            const url = new URI(this.endpoints.overallComparisonStatsSpan);

            if (months)
                url.addQuery('months', months);

            this.requester.get(url.valueOf()).then((success: HttpSuccess) => {
                if (Array.isArray(success.data.current) && Array.isArray(success.data.previous))
                    resolve(success.data);
                else
                    reject(null);
            }).catch((error: HttpError) => {
                reject(error)
            });

        });
    }
}