import * as React from "react";
import {APP_STRINGS, SummaryTimeSpan} from "../../types";
import {Moment} from "moment";
import moment = require("moment");
import {ChangeEvent, SyntheticEvent} from "react";
import {SYSTEM_DATE_FORMAT} from "../../Constants";
import DateVersusLabel from "../Campaigns/DateVersusLabel";
import DateVersus from "../Campaigns/DateVersus";

type Props = {
    onChange: (dates: Moment[]) => void,
};

type State = {
    summaryTimeSpan: SummaryTimeSpan,
}

class TimeSpanDropdown extends React.Component<Props, State>{

    state = {
        summaryTimeSpan: SummaryTimeSpan.SevenDays,
    };

    componentDidUpdate(prevProps: Props, prevState: State) {

        if (this.state.summaryTimeSpan !== prevState.summaryTimeSpan) {
            this.props.onChange(TimeSpanDropdown.getDates(this.state.summaryTimeSpan));
        }
    }

    render() {

        // const dates = TimeSpanDropdown.getDates(this.state.summaryTimeSpan);

        return (
            <>

                <select
                    value={this.state.summaryTimeSpan}
                    onChange={(e) => {
                        this.setState({
                            summaryTimeSpan: (e.currentTarget.value as SummaryTimeSpan),
                        });
                    }}
                >
                    {/*{this.option(SummaryTimeSpan.Custom, 'Custom')}*/}
                    {this.option(SummaryTimeSpan.Today, APP_STRINGS.dashboard.periodDropdown.today)}
                    {this.option(SummaryTimeSpan.Yesterday, APP_STRINGS.dashboard.periodDropdown.yesterday)}
                    {this.option(SummaryTimeSpan.SevenDays, APP_STRINGS.dashboard.periodDropdown.last7Days)}
                    {this.option(SummaryTimeSpan.OneMonth, APP_STRINGS.dashboard.periodDropdown.lastMonth)}
                    {this.option(SummaryTimeSpan.ThreeMonths, APP_STRINGS.dashboard.periodDropdown.last3Months)}
                    {this.option(SummaryTimeSpan.SixMonths, APP_STRINGS.dashboard.periodDropdown.last6Months)}
                    {this.option(SummaryTimeSpan.Year, APP_STRINGS.dashboard.periodDropdown.lastYear)}
                </select>

                {/*{(this.props.summary && this.state.summaryTimeSpan !== SummaryTimeSpan.Custom) &&*/}

                    {/*<DateVersusLabel*/}
                        {/*dates={TimeSpanDropdown.getDates(this.state.summaryTimeSpan)}*/}
                        {/*format={SYSTEM_DATE_FORMAT}*/}
                    {/*/>*/}
                {/*}*/}

                {/*{(this.state.summaryTimeSpan === SummaryTimeSpan.Custom) &&*/}
                    {/*<DateVersus*/}
                        {/*dates={dates}*/}
                        {/*onChange={(dates: Moment[]) => {*/}
                            {/*this.props.onChange(dates);*/}
                        {/*}}*/}
                        {/*onClickSearch={(newDates: Moment[]) => {*/}
                            {/*this.props.onChange(newDates);*/}
                        {/*}}*/}
                    {/*/>*/}
                {/*}*/}
            </>
        )
    }

    private option = (value: any, label: string) => {

        return (
            <option
                key={`${value}-${label}`}
                value={value}
            >
                {label}
            </option>
        )
    };

    public static getDates = (span: SummaryTimeSpan): Moment[] => {

        switch (span) {
            case SummaryTimeSpan.Today:{

                const start = moment().startOf('day');
                const end = start.clone().endOf('day');

                const start2 = start.clone().startOf('day').subtract(1, 'days');
                const end2 = start2.clone().endOf('day');

                return [
                    start,
                    end,
                    start2,
                    end2,
                ];
            }
            case SummaryTimeSpan.Yesterday: {

                const start = moment().startOf('day').subtract(1, 'days');
                const end = start.clone().endOf('day');

                const start2 = start.clone().startOf('day').subtract(1, 'days');
                const end2 = start2.clone().endOf('day');

                return [
                    start,
                    end,
                    start2,
                    end2,
                ];
            }
            case SummaryTimeSpan.SevenDays: {

                const count = 6;
                const start = moment().startOf('day').subtract(count, 'days');
                const end = moment().endOf('day');

                const start2 = start.clone().startOf('day').subtract(count, 'days');
                const end2 = start.clone().subtract(1, 'day').endOf('day');

                return [
                    start,
                    end,
                    start2,
                    end2,
                ];
            }
            case SummaryTimeSpan.OneMonth:
            case SummaryTimeSpan.ThreeMonths:
            case SummaryTimeSpan.SixMonths:
            case SummaryTimeSpan.Year:
            {
                const counts = {
                    [SummaryTimeSpan.OneMonth]: 1,
                    [SummaryTimeSpan.ThreeMonths]: 3,
                    [SummaryTimeSpan.SixMonths]: 6,
                    [SummaryTimeSpan.Year]: 12,

                };
                const count = counts[span];
                const start = moment().startOf('month').subtract(count, 'months');
                const end  = moment().endOf('month').subtract(1, 'months');

                const start2 = start.clone().startOf('month').subtract(count, 'months');
                const end2 = start.clone().subtract(1, 'day');

                return [
                    start,
                    end,
                    start2,
                    end2,
                ];
            }
        }
    };
}


export default TimeSpanDropdown;