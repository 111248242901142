import * as React from "react";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend} from "recharts";
import {APP_STRINGS, StatsChartYCol, StatsChartYColLabels} from "../../types";
import {Moment} from "moment";
import {SYSTEM_DATE_FORMAT} from "../../Constants";
import { sortBy, findIndex } from "lodash";
import CustomLegend from "./CustomLegend";
import DailyPerformanceLineChartTooltip from "./DailyPerformanceLineChartTooltip";
import {AllCampaignDailyStats} from "signature-public-api-sdk";

type Props = {
    allCampaignDailyStats: AllCampaignDailyStats[],
    chartsValueKey: StatsChartYCol,
    startDate: Moment,
    endDate: Moment,
    lineColours: string[],
};

const amountKey = 'amount';

const CampaignDailyPerformanceLineChart = (args: Props) => {

    const lines = args.allCampaignDailyStats ? args.allCampaignDailyStats.length : 1;
    const data = EmptyData(lines, args.startDate, args.endDate);

    let stats: AllCampaignDailyStats[] = args.allCampaignDailyStats;

    if (stats) {

        stats = sortBy(stats, (stat: AllCampaignDailyStats) => {return stat.campaignId});

        for (let i = 0; i < lines; i++) {
            const campaignStats: AllCampaignDailyStats = stats[i];

            for (const item of data) {
                const statIndex = findIndex(campaignStats.days, {date: item.date});

                if (statIndex !== -1) {
                    item[`${amountKey}${i}`] = campaignStats.days[statIndex][args.chartsValueKey];
                    item.campaigns.push(campaignStats.days[statIndex]);
                }
            }
        }
    }

    const graphLines = [];

    for (let i = 0; i < lines; i++) {
        graphLines.push(
            <Line key={`graph-line-${i}`} dataKey={`${amountKey}${i}`} stroke={args.lineColours[i]} strokeWidth={3}/>
        )
    }

    const CustomLegendContent = (
        <CustomLegend>
            {stats ? stats.map((stat: AllCampaignDailyStats, index: number) => {
                return (
                    <div
                        style={{color: args.lineColours[index]}}
                        key={`legend-line-${index}`}
                    >
                        <strong>{stat.campaignId}</strong>
                    </div>
                )
            }) : null}
        </CustomLegend>
    );

    const CustomTooltip = (props: any) => {
        return (
            <DailyPerformanceLineChartTooltip
                {...props}
                chartsValueKey={args.chartsValueKey}
            />
        );
    };

    return (
        <ResponsiveContainer width={'100%'} height={'100%'}>
            <LineChart
                data={data}
                margin={{ top: 10, right: 10, left: 10, bottom: 50 }}
            >
                <XAxis label={{ value: APP_STRINGS.charts.date, position: 'bottom' }} dataKey="date" />
                <YAxis label={{ value: StatsChartYColLabels()[args.chartsValueKey], angle: -90, position: 'insideLeft' }} />

                <CartesianGrid strokeDasharray="3 3"/>
                {graphLines}
                <Tooltip content={CustomTooltip}/>
                <Legend content={CustomLegendContent} verticalAlign={'top'} layout={'horizontal'}/>
            </LineChart>
        </ResponsiveContainer>
    );
};

const EmptyData = (lines: number, startDate: Moment, endDate: Moment) => {

    const start = startDate.clone();
    const array = [];

    while (start.diff(endDate, 'days') < 0) {

        const item = {
            date: start.format(SYSTEM_DATE_FORMAT),
            campaigns: [],
        };

        for (let i = 0; i < lines; i++) {
            item[`${amountKey}${i}`] = 0;
        }

        array.push(item);

        start.add(1, 'days');
    }

    return array;
};

export default CampaignDailyPerformanceLineChart;