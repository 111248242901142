import LocalizedStrings from 'localized-strings';
import {EN} from "./en";
import {PL} from "./pl";
import {PortalLanguage} from "../types";

export const strings = new LocalizedStrings({
    [PortalLanguage.English]: EN,
    [PortalLanguage.Polish]: PL,
});

export default strings;