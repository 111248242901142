import * as React from "react";
import { Link } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {APP_STRINGS, LoadingState} from "../types";
import {primaryButtonClass} from "../branding";

type Props = {
}

export default class ForgottenPassword extends React.Component<Props> {

    render() {
        // ${this.props.loginState.state === LoadingState.Pending ? 'is-loading' : ''}

        return (
            <div id="forgotten-password">
                <h1>{APP_STRINGS.forgotPassword.title}</h1>

                <div className="field">
                    <div className="control has-icons-left">
                        <input
                            className={'input'}
                            type="text"
                            placeholder={APP_STRINGS.forgotPassword.usernamePlaceholder}
                        />
                        <span className="icon is-left">
                          <FontAwesomeIcon icon={'envelope'}/>
                        </span>
                    </div>
                </div>
                <div className={'controls'}>

                    <a
                        className={`button ${primaryButtonClass()} is-pulled-right `}
                        onClick={() => {
                        }}
                    >
                        {APP_STRINGS.forgotPassword.resetButtonLabel}
                    </a>
                </div>
                {/*<Link*/}
                    {/*to="/"*/}
                {/*>*/}

                {/*</Link>*/}
            </div>
        );
    }
}
