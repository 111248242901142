import * as React from "react";
import { AffiliateApplication, PaginatedResults } from "signature-public-api-sdk";
import { APP_STRINGS, GetApplications } from "../../types";
import * as numeral from "numeral";
import moment = require("moment");
import { PRETTY_DATE_FORMAT } from "../../Constants";
import { CURRENCY_FORMAT } from "../../locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";

type Props = {
    loading: boolean,
    applications: PaginatedResults<AffiliateApplication>|null,
    getApplications: GetApplications,
}

const limit = 20;

export default class Applications extends React.Component<Props> {

    componentDidMount() {

        if (this.props.applications === null || this.props.applications === undefined) {
            this.props.getApplications(limit, 1);
        }
    }

    render() {

        const showApps = this.props.applications !== undefined && this.props.applications !== null && !this.props.loading;

        //the next/previous buttons don't actually load the right pages so some lucky bugger will need to make it work

        return (
            <div id="applications">
                <h1 className="is-flex align-items-center">
                    {APP_STRINGS.applications.title}
                </h1>
                <div className="applications-table-wrapper">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>{APP_STRINGS.applications.table.appId}</th>
                            <th>{APP_STRINGS.applications.table.created}</th>
                            <th>{APP_STRINGS.applications.table.leadApplicant}</th>
                            <th>{APP_STRINGS.applications.table.appType}</th>
                            <th>{APP_STRINGS.applications.table.loanType}</th>
                            <th>{APP_STRINGS.applications.table.stage}</th>
                            <th>{APP_STRINGS.applications.table.loanAmount}</th>
                        </tr>
                        </thead>
                        <tbody>

                        {this.props.loading &&
                            <tr>
                                <td colSpan={7}>
                                    <FontAwesomeIcon icon="spinner" pulse={true}/>
                                </td>
                            </tr>
                        }
                        {showApps &&
                            <>
                                {this.props.applications.items.map((application: AffiliateApplication) => {
                                    return (
                                        <tr key={`app-row-${application.id}`}>
                                            <td>{application.id}</td>
                                            <td>{moment(application.createdAt).format(PRETTY_DATE_FORMAT)}</td>
                                            <td>{application.primaryApplicantName}</td>
                                            <td>{application.applicationType}</td>
                                            <td>{application.loanType}</td>
                                            <td>{application.currentStageName}</td>
                                            <td>{numeral(application.loanAmount).format(CURRENCY_FORMAT)}</td>
                                        </tr>
                                    )
                                })}
                            </>
                        }
                        </tbody>
                    </table>
                </div>

                {(showApps && this.props.applications.totalPages > 1) &&
                    <ReactPaginate
                        breakLabel={"..."}
                        breakClassName={"pagination-break"}
                        pageCount={this.props.applications.totalPages}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={5}
                        forcePage={this.props.applications.currentPage - 1}
                        onPageChange={(page: { selected: number }) => {
                            this.props.getApplications(limit, page.selected + 1)
                        }}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                    />
                }
            </div>
        )
    }
}