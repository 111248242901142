import { LoanApiEndpoints } from "../../Types";
import { IHttpRequester } from "../../Interfaces/IHttpRequester";
import { ClientUsers } from "./ClientUsers";
import { ClientApplications } from "./ClientApplications";
import { ClientDocuments } from "./ClientDocuments";
import { ClientEmails } from "./ClientEmails";

export class ClientPortal {

    private readonly _users: ClientUsers;
    private readonly _applications: ClientApplications;
    private readonly _documents: ClientDocuments;
    private readonly _emails: ClientEmails;

    constructor(endpoints: LoanApiEndpoints, requester: IHttpRequester) {
        this._users = new ClientUsers(endpoints, requester);
        this._applications = new ClientApplications(endpoints, requester);
        this._documents= new ClientDocuments(endpoints, requester);
        this._emails= new ClientEmails(endpoints, requester);
    }

    get Users(): ClientUsers {
        return this._users;
    }

    get Applications(): ClientApplications {
        return this._applications;
    }

    get Documents(): ClientDocuments {
        return this._documents;
    }

    get Email(): ClientEmails {
        return this._emails;
    }
}