import { IHttpRequester } from "../../Interfaces/IHttpRequester";
import { HttpError, HttpSuccess, LoanApiEndpoints, PasswordResetDeliveryMethod } from "../../Types";
import * as URI from "urijs";
import { RegistrationTokenVerificationResponse } from "../../ResponseTypes";

require("urijs/src/URITemplate");

export class ClientEmails {

    private endpoints: LoanApiEndpoints;
    private requester: IHttpRequester;

    constructor(endpoints: LoanApiEndpoints, requester: IHttpRequester) {
        this.endpoints = endpoints;
        this.requester = requester;
    }

    /**
     * Request a password reset email to be sent.
     *
     * @param to
     * @param method
     */
    public requestPasswordReset(to: string, method: PasswordResetDeliveryMethod): Promise<HttpSuccess> {

        return new Promise((resolve: (success: HttpSuccess) => void, reject: (error: HttpError|null) => void) => {

            let data = {};

            switch (method) {
                case PasswordResetDeliveryMethod.SMS:
                    data = {mobile: to};
                    break;
                case PasswordResetDeliveryMethod.Email:
                default:
                    data = {email: to};
                    break;
            }

            this.requester.post(this.endpoints.clientRequestPasswordResetEmail, data).then((success: HttpSuccess) => {
                resolve(success);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }

    /**
     * Check the password reset token is ok.
     *
     * @param token
     */
    public validatePasswordResetToken(token: string): Promise<HttpSuccess> {

        return new Promise((resolve: (success: HttpSuccess) => void, reject: (error: HttpError|null) => void) => {

            const path = URI.expand(this.endpoints.clientValidatePasswordResetToken, {
                token: token,
            });

            this.requester.get(path.valueOf()).then((success: HttpSuccess) => {
                resolve(success);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }

    /**
     * Perform the password reset.
     *
     * @param token
     * @param password
     */
    public performPasswordReset(token: string, password: string): Promise<HttpSuccess> {

        return new Promise((resolve: (success: HttpSuccess) => void, reject: (error: HttpError|null) => void) => {

            const path = URI.expand(this.endpoints.clientPerformPasswordReset, {
                token: token,
            });

            this.requester.post(path.valueOf(), {password: password}).then((success: HttpSuccess) => {
                resolve(success);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }

    /**
     * Request an email verification email to be sent.
     *
     * @param email
     */
    public requestEmailVerification(email: string): Promise<HttpSuccess> {

        return new Promise((resolve: (success: HttpSuccess) => void, reject: (error: HttpError|null) => void) => {
            this.requester.post(this.endpoints.clientRequestEmailVerificationEmail, {email: email}).then((success: HttpSuccess) => {
                resolve(success);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }

    /**
     * Check the email verification token is ok.
     *
     * @param token
     */
    public validateEmailVerificationToken(token: string): Promise<HttpSuccess> {

        return new Promise((resolve: (success: HttpSuccess) => void, reject: (error: HttpError|null) => void) => {

            const path = URI.expand(this.endpoints.clientValidateEmailVerificationToken, {
                token: token,
            });

            this.requester.get(path.valueOf()).then((success: HttpSuccess) => {
                resolve(success);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }

    /**
     * Perform the email verification.
     *
     * @param token
     */
    public performEmailVerification(token: string): Promise<HttpSuccess> {

        return new Promise((resolve: (success: HttpSuccess) => void, reject: (error: HttpError|null) => void) => {

            const path = URI.expand(this.endpoints.clientPerformEmailVerification, {
                token: token,
            });

            this.requester.post(path.valueOf(), null).then((success: HttpSuccess) => {
                resolve(success);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }

    /**
     * Check the email verification token is ok.
     *
     * @param token
     */
    public validateRegistrationToken(token: string): Promise<RegistrationTokenVerificationResponse> {

        return new Promise((resolve: (success: RegistrationTokenVerificationResponse) => void, reject: (error: HttpError|null) => void) => {

            const path = URI.expand(this.endpoints.clientValidateRegistrationToken, {
                token: token,
            });

            this.requester.get(path.valueOf()).then((success: HttpSuccess) => {
                resolve(success.data);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }
}