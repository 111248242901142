import { IHttpRequester } from "../../Interfaces/IHttpRequester";
import { HttpError, HttpSuccess, LoanApiEndpoints, User } from "../../Types";
import { MeResponse } from "../../ResponseTypes";
import * as URI from "urijs";

export class ClientUsers {

    private endpoints: LoanApiEndpoints;
    private requester: IHttpRequester;

    constructor(endpoints: LoanApiEndpoints, requester: IHttpRequester) {
        this.endpoints = endpoints;
        this.requester = requester;
    }

    /**
     * Get basic information about the currently authenticated user.
     */
    public me(): Promise<MeResponse> {

        return new Promise<MeResponse>((resolve: (data: MeResponse, success: HttpSuccess) => void, reject: (error: HttpError|null) => void) => {
            this.requester.get(this.endpoints.clientMe).then((success: HttpSuccess) => {
                resolve(success.data, success);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }

    /**
     * Update an existing public user.
     *
     * @param data
     */
    public update(data: User): Promise<MeResponse> {

        return new Promise<MeResponse>((resolve: (data: MeResponse, success: HttpSuccess) => void, reject: (error: HttpError|null) => void) => {
            this.requester.put(this.endpoints.clientMe, data).then((success: HttpSuccess) => {
                resolve(success.data, success);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }

    /**
     * Create a new public portal user on the system.
     * @param data
     * @param token
     */
    public create(data: User, token?: string): Promise<MeResponse> {

        return new Promise<MeResponse>((resolve: (data: MeResponse, success: HttpSuccess) => void, reject: (error: HttpError|null) => void) => {

            const path = new URI(this.endpoints.clientCreateUser);

            if (token) {
                path.addQuery('token', token);
            }

            this.requester.post(path.valueOf(), data).then((success: HttpSuccess) => {
                resolve(success.data, success);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }

    /**
     * Update The current user's password.
     * @param currentPassword
     * @param newPassword
     */
    public updatePassword(currentPassword: string, newPassword: string): Promise<HttpSuccess> {

        return new Promise((resolve: (success: HttpSuccess) => void, reject: (error: HttpError|null) => void) => {
            this.requester.put(this.endpoints.clientUpdatePassword, {password: currentPassword, newPassword: newPassword}).then((success: HttpSuccess) => {
                resolve(success);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }
}
