import {IHttpRequester} from "../../Interfaces/IHttpRequester";
import {HttpError, HttpSuccess, LoanApiEndpoints} from "../../Types";
import {
    AllCampaignDailyStats,
    CampaignSummaryStats,
} from "../../ResponseTypes";
import * as URI from "urijs";

export class AffiliateCampaigns {

    private endpoints: LoanApiEndpoints;
    private requester: IHttpRequester;

    constructor(endpoints: LoanApiEndpoints, requester: IHttpRequester) {
        this.endpoints = endpoints;
        this.requester = requester;
    }

    public overallStats(startDate: string, endDate: string): Promise<CampaignSummaryStats> {

        return new Promise<CampaignSummaryStats>((resolve: (data: CampaignSummaryStats) => void, reject: (error: HttpError|HttpSuccess|null) => void) => {

            const url = new URI(this.endpoints.campaignsSummaryStats);

            if (startDate)
                url.addQuery('startDate', startDate);

            if (endDate)
                url.addQuery('endDate', endDate);

            this.requester.get(url.valueOf()).then((success: HttpSuccess) => {
                if (typeof(success.data) === "object")
                    resolve(success.data);
                else
                    reject(success);
            }).catch((error: HttpError) => {
                reject(error)
            });

        });
    }

    public allOverallStats(startDate: string, endDate: string): Promise<CampaignSummaryStats[]> {

        return new Promise<CampaignSummaryStats[]>((resolve: (data: CampaignSummaryStats[]) => void, reject: (error: HttpError|HttpSuccess|null) => void) => {

            const url = new URI(this.endpoints.allCampaignsSummaryStats);

            if (startDate)
                url.addQuery('startDate', startDate);

            if (endDate)
                url.addQuery('endDate', endDate);

            this.requester.get(url.valueOf()).then((success: HttpSuccess) => {
                if (Array.isArray(success.data))
                    resolve(success.data);
                else
                    reject(success);
            }).catch((error: HttpError) => {
                reject(error)
            });

        });
    }

    public allDailyStats(startDate: string, endDate: string): Promise<AllCampaignDailyStats[]> {

        return new Promise<AllCampaignDailyStats[]>((resolve: (data: AllCampaignDailyStats[]) => void, reject: (error: HttpError|HttpSuccess|null) => void) => {

            const url = new URI(this.endpoints.allCampaignsDailyStats);

            if (startDate)
                url.addQuery('startDate', startDate);

            if (endDate)
                url.addQuery('endDate', endDate);

            this.requester.get(url.valueOf()).then((success: HttpSuccess) => {
                if (Array.isArray(success.data))
                    resolve(success.data);
                else
                    reject(success);
            }).catch((error: HttpError) => {
                reject(error)
            });

        });
    }
}