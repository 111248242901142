import { API } from "signature-public-api-sdk";
import { PortalLanguage } from "../types";
import { defaultLanguage } from "../branding";



export const api = (): API => {
    return new API('');
};

export const updateLanguage = (language: PortalLanguage) => {

    localStorage.setItem(LANG_KEY, language);
    window.location.reload();
};

const LANG_KEY = 'portalLanguage';

export const getSavedLanguage = (): PortalLanguage => {

    const saved = localStorage.getItem(LANG_KEY);

    if (saved !== null && saved !== undefined)
        return saved as PortalLanguage;

    return defaultLanguage();

};