import {BrandName, PortalLanguage, WebpackEnv} from "./types";
import {
    DASHBOARD_ROUTE,
    GRAPH_CURRENT_LINE_COLOUR_CRED4, GRAPH_CURRENT_LINE_COLOUR_LOAN,
    GRAPH_CURRENT_LINE_COLOUR_M3,
    GRAPH_PREVIOUS_LINE_COLOUR_CRED4, GRAPH_PREVIOUS_LINE_COLOUR_LOAN,
    GRAPH_PREVIOUS_LINE_COLOUR_M3, REPORTS_ROUTE
} from "./Constants";

export function getEnv(): WebpackEnv {
    //@ts-ignore
    return process.env as WebpackEnv;
}

export function getBrand(): BrandName {
    return getEnv().brand.name;
}

export function successfulLoginRedirect() {

    if (showDashboardPage())
        return DASHBOARD_ROUTE;

    return REPORTS_ROUTE;
}

export function showDashboardPage() {
    return getEnv().showDashboardPage;
}

export function showApplicationPage() {
    return getEnv().showApplicationPage;
}

export function primaryButtonClass() {

    switch (getBrand()) {
        case BrandName.Loan:
            return 'btn-pink';
        case BrandName.Cred4:
            return 'is-dark';
        case BrandName.M3:
            return 'is-primary';
    }
}

export function chartCurrentPeriodColour() {


    switch (getBrand()) {
        case BrandName.Cred4:
            return GRAPH_CURRENT_LINE_COLOUR_CRED4;
        case BrandName.M3:
            return GRAPH_CURRENT_LINE_COLOUR_M3;
        case BrandName.Loan:
            return GRAPH_CURRENT_LINE_COLOUR_LOAN;
    }
}

export function chartPreviousPeriodColour(): string {

    switch (getBrand()) {
        case BrandName.Cred4:
            return GRAPH_PREVIOUS_LINE_COLOUR_CRED4;
        case BrandName.M3:
            return GRAPH_PREVIOUS_LINE_COLOUR_M3;
        case BrandName.Loan:
            return GRAPH_PREVIOUS_LINE_COLOUR_LOAN;
    }
}

export function chartLineColours(): string[] {

    switch (getBrand()) {
        case BrandName.Loan:
            return [
                '#FF2391',
                '#C80165',
                '#2CA58D',
                '#9FBA80',
                '#9F8054',
                '#BA3A46',
                '#F3B136',
                '#734F2F',
                '#D1B675',
                '#53142C',
                '#0A2342',
                '#84BC9C',
                '#CD6338',
                '#8A090A',
                '#252021',
                '#A69FA0',
                '#3CA39A',
                '#3E582D',
                '#CD4E30',
                '#273441',
            ];

        case BrandName.Cred4:
        case BrandName.M3:
            return [
                '#2CA58D',
                '#9FBA80',
                '#9F8054',
                '#BA3A46',
                '#F3B136',
                '#734F2F',
                '#D1B675',
                '#53142C',
                '#0A2342',
                '#84BC9C',
                '#CD6338',
                '#8A090A',
                '#252021',
                '#A69FA0',
                '#3CA39A',
                '#3E582D',
                '#CD4E30',
                '#273441',
                '#F6A615',
                '#D68A64',
            ];
    }
}

export function showLanguageSwitch(): boolean {

    switch (getBrand()) {
        case BrandName.Cred4:
            return true;
        case BrandName.M3:
        case BrandName.Loan:
            return false;
    }
}

export function defaultLanguage(): PortalLanguage {

    switch (getBrand()) {
        case BrandName.Cred4:
            return PortalLanguage.Polish;
        case BrandName.M3:
        case BrandName.Loan:
            return PortalLanguage.English;
    }
}
