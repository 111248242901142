import {AnyAction, Reducer} from "redux";
import {
    FAILED_LOGIN,
    FAILED_LOGOUT, FAILED_UPDATE_PASSWORD, FINISHED_UPDATE_PASSWORD,
    START_LOGIN,
    START_LOGOUT, START_UPDATE_PASSWORD,
    SUCCESSFUL_LOGIN,
    SUCCESSFUL_LOGOUT
} from "./Actions/User";
import {
    FAILED_LOADING_ALL_CAMPAIGN_DAILY_STATS,
    FAILED_LOADING_ALL_CAMPAIGN_OVERALL_STATS,
    FAILED_LOADING_CAMPAIGN_OVERALL_STATS,
    FAILED_LOADING_OVERALL_STATS,
    FAILED_LOADING_SUMMARY,
    FINISHED_LOADING_ALL_CAMPAIGN_DAILY_STATS,
    FINISHED_LOADING_ALL_CAMPAIGN_OVERALL_STATS,
    FINISHED_LOADING_CAMPAIGN_OVERALL_STATS,
    FINISHED_LOADING_OVERALL_STATS,
    FINISHED_LOADING_SUMMARY, SET_DASHBOARD_SPAN_AMOUNT,
    STARTED_LOADING_ALL_CAMPAIGN_DAILY_STATS,
    STARTED_LOADING_ALL_CAMPAIGN_OVERALL_STATS,
    STARTED_LOADING_CAMPAIGN_OVERALL_STATS,
    STARTED_LOADING_OVERALL_STATS,
    STARTED_LOADING_SUMMARY
} from "./Actions/Reports";
import {LoadingState, NetworkState, PortalLanguage} from "./types";
import {
    AffiliateApplication,
    AllCampaignDailyStats,
    CampaignSummaryStats, PaginatedResults,
    TimeOverallStatsResponse,
    TimePeriodComparisonStatsResponse,
    User
} from "signature-public-api-sdk";
import {getSavedLanguage} from "./Actions/Actions";
import { FAILED_DOWNLOAD_APPS, FINISHED_DOWNLOAD_APPS, STARTED_DOWNLOAD_APPS } from "./Actions/Applications";

export type PortalReducerState = {
    apiBase: string,

    user: User,
    loginState: NetworkState,
    logoutState: NetworkState,
    passwordUpdateState: NetworkState,

    summary: TimePeriodComparisonStatsResponse,
    overallStats: TimeOverallStatsResponse,

    overallStatsSpanAmount: number,

    summaryState: NetworkState,
    overallStatsState: NetworkState,

    campaignOverallStats: CampaignSummaryStats,
    campaignSummaryState: NetworkState,

    allCampaignOverallStats: CampaignSummaryStats[],
    allCampaignSummaryState: NetworkState,

    allCampaignDailyStats: AllCampaignDailyStats[],
    allCampaignDailyStatsState: NetworkState,

    loadingApplications: boolean,
    applications: PaginatedResults<AffiliateApplication>,

    language: PortalLanguage,
}

export const PortalReducer: Reducer<PortalReducerState> = (state: PortalReducerState, action: AnyAction) => {

    switch (action.type) {

        case SET_DASHBOARD_SPAN_AMOUNT:

            return {
                ...state,
                overallStatsSpanAmount: action.amount,
            };

        case STARTED_LOADING_SUMMARY:

            return {
                ...state,
                summaryState: {
                    state: LoadingState.Pending,
                    error: null,
                }
            };

        case FAILED_LOADING_SUMMARY:

            return {
                ...state,
                summaryState: {
                    state: LoadingState.Error,
                    error: action.error,
                }
            };

        case FINISHED_LOADING_SUMMARY:

            return {
                ...state,
                summary: action.summary,
                summaryState: {
                    state: LoadingState.Nothing,
                    error: null,
                }
            };

        case STARTED_LOADING_OVERALL_STATS:

            return {
                ...state,
                overallStatsState: {
                    state: LoadingState.Pending,
                    error: null,
                }
            };

        case FAILED_LOADING_OVERALL_STATS:

            return {
                ...state,
                overallStatsState: {
                    state: LoadingState.Error,
                    error: action.error,
                }
            };

        case FINISHED_LOADING_OVERALL_STATS:

            return {
                ...state,
                overallStats: action.stats,
                overallStatsState: {
                    state: LoadingState.Nothing,
                    error: null,
                }
            };

        case STARTED_LOADING_CAMPAIGN_OVERALL_STATS:

            return {
                ...state,
                campaignSummaryState: {
                    state: LoadingState.Pending,
                    error: null,
                }
            };

        case FAILED_LOADING_CAMPAIGN_OVERALL_STATS:

            return {
                ...state,
                campaignSummaryState: {
                    state: LoadingState.Error,
                    error: action.error,
                }
            };

        case FINISHED_LOADING_CAMPAIGN_OVERALL_STATS:

            return {
                ...state,
                campaignOverallStats: action.stats,
                campaignSummaryState: {
                    state: LoadingState.Nothing,
                    error: null,
                }
            };


        case STARTED_LOADING_ALL_CAMPAIGN_OVERALL_STATS:

            return {
                ...state,
                allCampaignSummaryState: {
                    state: LoadingState.Pending,
                    error: null,
                }
            };

        case FAILED_LOADING_ALL_CAMPAIGN_OVERALL_STATS:

            return {
                ...state,
                allCampaignSummaryState: {
                    state: LoadingState.Error,
                    error: action.error,
                }
            };

        case FINISHED_LOADING_ALL_CAMPAIGN_OVERALL_STATS:

            return {
                ...state,
                allCampaignOverallStats: action.stats,
                allCampaignSummaryState: {
                    state: LoadingState.Nothing,
                    error: null,
                }
            };



        case STARTED_LOADING_ALL_CAMPAIGN_DAILY_STATS:

            return {
                ...state,
                allCampaignDailyStatsState: {
                    state: LoadingState.Pending,
                    error: null,
                }
            };

        case FAILED_LOADING_ALL_CAMPAIGN_DAILY_STATS:

            return {
                ...state,
                allCampaignDailyStatsState: {
                    state: LoadingState.Error,
                    error: action.error,
                }
            };

        case FINISHED_LOADING_ALL_CAMPAIGN_DAILY_STATS:

            return {
                ...state,
                allCampaignDailyStats: action.stats,
                allCampaignDailyStatsState: {
                    state: LoadingState.Nothing,
                    error: null,
                }
            };

        case START_LOGIN:

            return {
                ...state,
                loginState: {
                    state: LoadingState.Pending,
                    error: null,
                }
            };

        case FAILED_LOGIN:
            return {
                ...state,
                loginState: {
                    state: LoadingState.Error,
                    error: action.error,
                }
            };

        case SUCCESSFUL_LOGIN:

            return {
                ...state,
                user: action.user,
                loginState: {
                    state: LoadingState.Nothing,
                    error: null,
                }
            };

        case START_LOGOUT:

            return {
                ...state,
                logoutState: {
                    state: LoadingState.Pending,
                    error: null,
                },
            };

        case FAILED_LOGOUT:

            return {
                ...state,
                logoutState: {
                    state: LoadingState.Error,
                    error: action.error,
                },
            };

        case SUCCESSFUL_LOGOUT:

            return {
                ...state,
                user: null,
                logoutState: {
                    state: LoadingState.Nothing,
                    error: null,
                },
                summary: null,
                overallStats: null,
                campaignOverallStats: null,
                allCampaignOverallStats: null,
                allCampaignDailyStats: null,
                applications: null,
                loadingApplications: false
            };

        case START_UPDATE_PASSWORD:

            return {
                ...state,
                passwordUpdateState: {
                    state: LoadingState.Pending,
                    error: null,
                },
            };

        case FAILED_UPDATE_PASSWORD:

            return {
                ...state,
                passwordUpdateState: {
                    state: LoadingState.Error,
                    error: action.error,
                },
            };

        case FINISHED_UPDATE_PASSWORD:

            return {
                ...state,
                passwordUpdateState: {
                    state: LoadingState.Nothing,
                    error: null,
                },
            };

        case STARTED_DOWNLOAD_APPS:

            return {
                ...state,
                loadingApplications: true,
            };

        case FINISHED_DOWNLOAD_APPS:

            return {
                ...state,
                loadingApplications: false,
                applications: action.data
            };

        case FAILED_DOWNLOAD_APPS:

            return {
                ...state,
                loadingApplications: false,
            };

    }

    if (typeof state == "undefined") {
        state = {
            apiBase: '/',
            user: null,
            loginState: {
                state: LoadingState.Nothing,
                error: null
            },
            logoutState: {
                state: LoadingState.Nothing,
                error: null
            },
            passwordUpdateState: {
                state: LoadingState.Nothing,
                error: null
            },
            overallStatsSpanAmount: 1,
            summary: null,
            overallStats: null,
            summaryState: {
                state: LoadingState.Nothing,
                error: null
            },
            overallStatsState: {
                state: LoadingState.Nothing,
                error: null
            },
            campaignOverallStats: null,
            campaignSummaryState: {
                state: LoadingState.Nothing,
                error: null
            },
            allCampaignOverallStats: null,
            allCampaignSummaryState: {
                state: LoadingState.Nothing,
                error: null
            },
            allCampaignDailyStats: null,
            allCampaignDailyStatsState: {
                state: LoadingState.Nothing,
                error: null,
            },
            loadingApplications: false,
            applications: null,
            language: getSavedLanguage(),
        }
    }

    return state;
};