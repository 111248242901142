import {api} from "./Actions";
import {
    AffiliateApplication,
    PaginatedResults,
    HttpError
} from "signature-public-api-sdk";

export const STARTED_DOWNLOAD_APPS = 'STARTED_DOWNLOAD_APPS';
export const FINISHED_DOWNLOAD_APPS = 'FINISHED_DOWNLOAD_APPS';
export const FAILED_DOWNLOAD_APPS = 'FAILED_DOWNLOAD_APPS';

const startedDownloadingApps = () => {
    return {
        type: STARTED_DOWNLOAD_APPS
    }
};

const failedDownloadingApps = (error: string) => {
    return {
        type: FAILED_DOWNLOAD_APPS,
        error: error,
    }
};

const finishedDownloadingApps = (data: PaginatedResults<AffiliateApplication>) => {
    return {
        type: FINISHED_DOWNLOAD_APPS,
        data: data,
    }
};

export const getApplications = (limit: number, page: number|null) => {
    return (dispatch, getState) => {

        dispatch(startedDownloadingApps());
        api().AffiliatePortal.Applications.list(limit, page).then((response: PaginatedResults<AffiliateApplication>) => {
            dispatch(finishedDownloadingApps(response));
        }).catch((error: HttpError) => {
            dispatch(failedDownloadingApps(error.response));
        });

    };
};