import * as React from "react";
import * as ReactDOM from "react-dom";
import {applyMiddleware, createStore, compose} from "redux";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import {PortalReducer, PortalReducerState} from "./PortalReducer";
import PortalContainer from "./Containers/PortalContainer";
import 'react-dates/initialize';
import {APP_STRINGS} from "./types";
import {getSavedLanguage} from "./Actions/Actions";


interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
}
declare var window: Window;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appElement = document.getElementById('app');

const initialState: PortalReducerState = JSON.parse(appElement.dataset.initialState);

initialState.language = getSavedLanguage();

const store = createStore(PortalReducer, initialState, composeEnhancers(applyMiddleware(
    thunk, // lets us dispatch() functions
)));

APP_STRINGS.setLanguage(initialState.language);

ReactDOM.render(
    <Provider store={store}>
        <PortalContainer/>
    </Provider>,
    appElement
);