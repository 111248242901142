import { LoanApiEndpoints } from "../../Types";
import { IHttpRequester } from "../../Interfaces/IHttpRequester";
import { AffiliateUsers } from "./AffiliateUsers";
import { AffiliateDashboard } from "./AffiliateDashboard";
import { AffiliateCampaigns } from "./AffiliateCampaigns";
import { AffiliateApplications } from "./AffiliateApplications";

export class AffiliatePortal {

    private readonly _users: AffiliateUsers;
    private readonly _dashboard: AffiliateDashboard;
    private readonly _campaigns: AffiliateCampaigns;
    private readonly _applications: AffiliateApplications;

    constructor(endpoints: LoanApiEndpoints, requester: IHttpRequester) {
        this._users = new AffiliateUsers(endpoints, requester);
        this._dashboard = new AffiliateDashboard(endpoints, requester);
        this._campaigns = new AffiliateCampaigns(endpoints, requester);
        this._applications = new AffiliateApplications(endpoints, requester);
    }

    get Users(): AffiliateUsers {
        return this._users;
    }

    get Dashboard(): AffiliateDashboard {
        return this._dashboard;
    }

    get Campaigns(): AffiliateCampaigns {
        return this._campaigns;
    }

    get Applications(): AffiliateApplications {
        return this._applications;
    }
}