import {api} from "./Actions";
import {
    AllCampaignDailyStats,
    CampaignSummaryStats,
    HttpError,
    TimeOverallStatsResponse,
    TimePeriodComparisonStatsResponse
} from "signature-public-api-sdk";

export const SET_DASHBOARD_SPAN_AMOUNT = 'SET_DASHBOARD_SPAN_AMOUNT';

export const STARTED_LOADING_SUMMARY = 'STARTED_LOADING_SUMMARY';
export const FINISHED_LOADING_SUMMARY = 'FINISHED_LOADING_SUMMARY';
export const FAILED_LOADING_SUMMARY = 'FAILED_LOADING_SUMMARY';

export const STARTED_LOADING_OVERALL_STATS = 'STARTED_LOADING_OVERALL_STATS';
export const FINISHED_LOADING_OVERALL_STATS = 'FINISHED_LOADING_OVERALL_STATS';
export const FAILED_LOADING_OVERALL_STATS = 'FAILED_LOADING_OVERALL_STATS';

export const STARTED_LOADING_CAMPAIGN_OVERALL_STATS = 'STARTED_LOADING_CAMPAIGN_OVERALL_STATS';
export const FINISHED_LOADING_CAMPAIGN_OVERALL_STATS = 'FINISHED_LOADING_CAMPAIGN_OVERALL_STATS';
export const FAILED_LOADING_CAMPAIGN_OVERALL_STATS = 'FAILED_LOADING_CAMPAIGN_OVERALL_STATS';

export const STARTED_LOADING_ALL_CAMPAIGN_OVERALL_STATS = 'STARTED_LOADING_ALL_CAMPAIGN_OVERALL_STATS';
export const FINISHED_LOADING_ALL_CAMPAIGN_OVERALL_STATS = 'FINISHED_LOADING_ALL_CAMPAIGN_OVERALL_STATS';
export const FAILED_LOADING_ALL_CAMPAIGN_OVERALL_STATS = 'FAILED_LOADING_ALL_CAMPAIGN_OVERALL_STATS';

export const STARTED_LOADING_ALL_CAMPAIGN_DAILY_STATS = 'STARTED_LOADING_ALL_CAMPAIGN_DAILY_STATS';
export const FINISHED_LOADING_ALL_CAMPAIGN_DAILY_STATS = 'FINISHED_LOADING_ALL_CAMPAIGN_DAILY_STATS';
export const FAILED_LOADING_ALL_CAMPAIGN_DAILY_STATS = 'FAILED_LOADING_ALL_CAMPAIGN_DAILY_STATS';


export const setDashboardSpanAmount = (amount: number) => {
    return {
        type: SET_DASHBOARD_SPAN_AMOUNT,
        amount: amount,
    }
};

export const startedLoadingSummary = () => {
    return {
        type: STARTED_LOADING_SUMMARY
    }
};

export const finishedLoadingSummary = (summary: TimePeriodComparisonStatsResponse) => {
    return {
        type: FINISHED_LOADING_SUMMARY,
        summary: summary
    }
};

export const failedLoadingSummary = () => {
    return {
        type: FAILED_LOADING_SUMMARY
    }
};

export const fetchSummary = (startDate: string, endDate?: string, startDate2?: string, endDate2?: string) => {

    return (dispatch, getState) => {

        dispatch(startedLoadingSummary());

        api().AffiliatePortal.Dashboard.timeComparisonStats(startDate, endDate, startDate2, endDate2).then((response: TimePeriodComparisonStatsResponse) => {
            dispatch(finishedLoadingSummary(response));
        }).catch((error: HttpError) => {
            dispatch(failedLoadingSummary());
        });

    };
};

export const startedLoadingOverallStats = () => {
    return {
        type: STARTED_LOADING_OVERALL_STATS,
    }
};

export const finishedLoadingOverallStats = (stats: TimeOverallStatsResponse) => {
    return {
        type: FINISHED_LOADING_OVERALL_STATS,
        stats: stats,
    }
};

export const failedLoadingOverallStats = () => {
    return {
        type: FAILED_LOADING_OVERALL_STATS,
    }
};

export const fetchOverallStats = (startDate: string, endDate?: string, startDate2?: string, endDate2?: string) => {

    return (dispatch, getState) => {
        dispatch(startedLoadingOverallStats());
        api().AffiliatePortal.Dashboard.timeOverallStats(startDate, endDate, startDate2, endDate2).then((response: TimeOverallStatsResponse) => {
            dispatch(finishedLoadingOverallStats(response));
        }).catch((error: HttpError) => {
            dispatch(failedLoadingOverallStats());
        });

    };
};

export const startedLoadingCampaignReports = () => {
    return {
        type: STARTED_LOADING_CAMPAIGN_OVERALL_STATS
    }
};

export const finishedLoadingCampaignReports = (stats: CampaignSummaryStats) => {
    return {
        type: FINISHED_LOADING_CAMPAIGN_OVERALL_STATS,
        stats: stats,
    }
};

export const failedLoadingCampaignReports = () => {
    return {
        type: FAILED_LOADING_CAMPAIGN_OVERALL_STATS
    }
};

export const fetchCampaignReports = (startDate: string, endDate: string) => {

    return (dispatch, getState) => {
        dispatch(startedLoadingCampaignReports());
        api().AffiliatePortal.Campaigns.overallStats(startDate, endDate).then((response: CampaignSummaryStats) => {
            dispatch(finishedLoadingCampaignReports(response));
        }).catch((error: HttpError) => {
            dispatch(failedLoadingCampaignReports());
        });

    };
};

export const startedLoadingAllCampaignReports = () => {
    return {
        type: STARTED_LOADING_ALL_CAMPAIGN_OVERALL_STATS
    }
};

export const finishedLoadingAllCampaignReports = (stats: CampaignSummaryStats[]) => {
    return {
        type: FINISHED_LOADING_ALL_CAMPAIGN_OVERALL_STATS,
        stats: stats,
    }
};

export const failedLoadingAllCampaignReports = () => {
    return {
        type: FAILED_LOADING_ALL_CAMPAIGN_OVERALL_STATS
    }
};

export const fetchAllCampaignReports = (startDate: string, endDate: string) => {

    return (dispatch, getState) => {
        dispatch(startedLoadingAllCampaignReports());
        api().AffiliatePortal.Campaigns.allOverallStats(startDate, endDate).then((response: CampaignSummaryStats[]) => {
            dispatch(finishedLoadingAllCampaignReports(response));
        }).catch((error: HttpError) => {
            dispatch(failedLoadingAllCampaignReports());
        });

    };
};

export const startedLoadingDailyCampaignStats = () => {
    return {
        type: STARTED_LOADING_ALL_CAMPAIGN_DAILY_STATS
    }
};

export const finishedLoadingDailyCampaignStats = (stats: AllCampaignDailyStats[]) => {
    return {
        type: FINISHED_LOADING_ALL_CAMPAIGN_DAILY_STATS,
        stats: stats,
    }
};

export const failedLoadingDailyCampaignStats = () => {
    return {
        type: FAILED_LOADING_ALL_CAMPAIGN_DAILY_STATS
    }
};

export const fetchAllDailyCampaignStats = (startDate: string, endDate: string) => {

    return (dispatch, getState) => {
        dispatch(startedLoadingDailyCampaignStats());
        api().AffiliatePortal.Campaigns.allDailyStats(startDate, endDate).then((response: AllCampaignDailyStats[]) => {
            dispatch(finishedLoadingDailyCampaignStats(response));
        }).catch((error: HttpError) => {
            dispatch(failedLoadingDailyCampaignStats());
        });

    };
};