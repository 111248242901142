import * as React from "react";

type Props = {
    children: any,
}

const CustomLegend = (args: Props) => {

    return (
        <div className={'custom-legend'}>
            {args.children}
        </div>
    )
};

export default CustomLegend;