import {api} from "./Actions";
import {LoginResponse} from "../types";
import {HttpError, User} from "signature-public-api-sdk";

export const START_LOGIN = 'START_LOGIN';
export const SUCCESSFUL_LOGIN = 'SUCCESSFUL_LOGIN';
export const FAILED_LOGIN = 'FAILED_LOGIN';

export const START_LOGOUT = 'START_LOGOUT';
export const SUCCESSFUL_LOGOUT = 'SUCCESSFUL_LOGOUT';
export const FAILED_LOGOUT = 'FAILED_LOGOUT';

export const START_UPDATE_PASSWORD = 'START_UPDATE_PASSWORD';
export const FINISHED_UPDATE_PASSWORD = 'FINISHED_UPDATE_PASSWORD';
export const FAILED_UPDATE_PASSWORD = 'FAILED_UPDATE_PASSWORD';

export const startedLoggingIn = () => {
    return {
        type: START_LOGIN
    }
};

export const failedLogin = (error: string) => {
    return {
        type: FAILED_LOGIN,
        error: error,
    }
};

export const successfulLogin = (user: User) => {
    return {
        type: SUCCESSFUL_LOGIN,
        user: user,
    }
};

export const login = (username: string, password: string) => {
    return (dispatch, getState) => {

        dispatch(startedLoggingIn());
        api().Auth.login(username, password).then((response: LoginResponse) => {
            dispatch(successfulLogin(response.user));
        }).catch((error: HttpError) => {
            dispatch(failedLogin(error.response));
        });

    };
};

export const startedLoggingOut = () => {
    return {
        type: START_LOGOUT
    }
};

export const successfulLogout = () => {
    return {
        type: SUCCESSFUL_LOGOUT,
    }
};

export const failedLogout = (error: string) => {
    return {
        type: FAILED_LOGOUT,
        error: error,
    }
};

export const logout = () => {
    return (dispatch, getState) => {

        dispatch(startedLoggingOut());
        api().Auth.logout().then(() => {
            dispatch(successfulLogout());
        }).catch((error: HttpError) => {
            dispatch(failedLogout(error.response));
        });

    };
};

const startedPasswordUpdate = () => {
    return {
        type: START_UPDATE_PASSWORD
    }
};

const finishedPasswordUpdate = () => {
    return {
        type: FINISHED_UPDATE_PASSWORD,
    }
};

const failedPasswordUpdate = (error: string) => {
    return {
        type: FAILED_UPDATE_PASSWORD,
        error: error,
    }
};

export const updatePassword = (currentPassword: string, newPassword: string) => {
    return (dispatch, getState) => {

        dispatch(startedPasswordUpdate());
        api().AffiliatePortal.Users.updatePassword(currentPassword, newPassword).then(() => {
            dispatch(finishedPasswordUpdate());
        }).catch((error: HttpError) => {
            dispatch(failedPasswordUpdate(error.response));
        });

    };
};