import {connect, Dispatch} from 'react-redux';
import App from "../Components/App";
import {
    fetchAllCampaignReports,
    fetchAllDailyCampaignStats,
    fetchCampaignReports,
    fetchOverallStats,
    fetchSummary, setDashboardSpanAmount
} from "../Actions/Reports";
import {login, logout, updatePassword} from "../Actions/User";
import {
    FetchAllCampaignDailyStatsAction,
    FetchAllCampaignReportsAction,
    FetchCampaignReportsAction,
    FetchOverallStatsAction,
    FetchSummaryAction, GetApplications,
    LoginAction,
    LogoutAction, NetworkState, PortalLanguage, SetDashboardSpanAmount, UpdateLanguageAction, UpdatePasswordAction
} from "../types";
import {PortalReducerState} from "../PortalReducer";
import {
    AffiliateApplication,
    AllCampaignDailyStats,
    CampaignSummaryStats, PaginatedResults,
    TimeOverallStatsResponse,
    TimePeriodComparisonStatsResponse,
    User
} from "signature-public-api-sdk";
import {updateLanguage} from "../Actions/Actions";
import { getApplications } from "../Actions/Applications";

export type PortalContainerState = {

    language: PortalLanguage,

    user: User,
    loginState: NetworkState,
    logoutState: NetworkState,
    passwordUpdateState: NetworkState,

    overallStatsSpanAmount: number,

    summary: TimePeriodComparisonStatsResponse,
    overallStats: TimeOverallStatsResponse,
    summaryState: NetworkState,
    overallStatsState: NetworkState,

    campaignOverallStats: CampaignSummaryStats,
    campaignSummaryState: NetworkState,

    allCampaignOverallStats: CampaignSummaryStats[],
    allCampaignSummaryState: NetworkState,

    allCampaignDailyStats: AllCampaignDailyStats[],
    allCampaignDailyStatsState: NetworkState,

    loadingApplications: boolean,
    applications: PaginatedResults<AffiliateApplication>,
}

export type PortalContainerProps = {
    updateLanguageAction: UpdateLanguageAction,
    setDashboardSpanAmount: SetDashboardSpanAmount,
    fetchSummary: FetchSummaryAction,
    fetchOverallStatsAction: FetchOverallStatsAction,
    fetchCampaignReports: FetchCampaignReportsAction,
    fetchAllCampaignReportsAction: FetchAllCampaignReportsAction,
    fetchAllCampaignDailyStatsAction: FetchAllCampaignDailyStatsAction,
    login: LoginAction,
    logout: LogoutAction,
    updatePassword: UpdatePasswordAction,
    getApplications: GetApplications,
}

const mapStateToProps = (state: PortalReducerState): PortalContainerState => {
    return {
        language: state.language,

        user: state.user,
        loginState: state.loginState,
        logoutState: state.logoutState,
        passwordUpdateState: state.passwordUpdateState,

        overallStatsSpanAmount: state.overallStatsSpanAmount,
        summary: state.summary,
        overallStats: state.overallStats,
        summaryState: state.summaryState,
        overallStatsState: state.overallStatsState,

        campaignOverallStats: state.campaignOverallStats,
        campaignSummaryState: state.campaignSummaryState,

        allCampaignOverallStats: state.allCampaignOverallStats,
        allCampaignSummaryState: state.allCampaignSummaryState,

        allCampaignDailyStats: state.allCampaignDailyStats,
        allCampaignDailyStatsState: state.allCampaignDailyStatsState,

        loadingApplications: state.loadingApplications === true,
        applications: state.applications,
    }
};

const mapDispatchToProps = (dispatch: Dispatch<number|string>): PortalContainerProps => {
    return {
        updateLanguageAction: (language: PortalLanguage) => {
            updateLanguage(language);
        },
        setDashboardSpanAmount: (amount: number) => {
            dispatch(setDashboardSpanAmount(amount));
        },
        fetchSummary: (startDate: string, endDate?: string, startDate2?: string, endDate2?: string) => {
            dispatch(fetchSummary(startDate, endDate, startDate2, endDate2));
        },
        fetchOverallStatsAction: (startDate: string, endDate?: string, startDate2?: string, endDate2?: string) => {
            dispatch(fetchOverallStats(startDate, endDate, startDate2, endDate2));
        },
        fetchCampaignReports: (startDate: string, endDate: string) => {
            dispatch(fetchCampaignReports(startDate, endDate));
        },
        fetchAllCampaignReportsAction: (startDate: string, endDate: string) => {
            dispatch(fetchAllCampaignReports(startDate, endDate));
        },
        fetchAllCampaignDailyStatsAction: (startDate: string, endDate: string) => {
            dispatch(fetchAllDailyCampaignStats(startDate, endDate));
        },
        login: (username: string, password: string) => {
            dispatch(login(username, password));
        },
        logout: () => {
            dispatch(logout());
        },
        updatePassword: (currentPassword: string, newPassword: string) => {
            dispatch(updatePassword(currentPassword, newPassword));
        },
        getApplications: (limit: number, page: number) => {
            dispatch(getApplications(limit, page));
        },
    }
};

const PortalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(App);

export default PortalContainer;