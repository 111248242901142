import * as React from "react";
import {FormatData} from "./FormatData";
import {APP_STRINGS, StatsChartYCol} from "../../types";
import {CampaignSummaryStats, User} from "signature-public-api-sdk";

type Props = {
    user: User,
    campaign: CampaignSummaryStats,
    subCampaigns: CampaignSummaryStats[],
    color: string,
    rowClass?: string,
}

type State = {
    expanded: boolean,
}

export default class CampaignRow extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            expanded: false
        };
    }

    render() {

        const campaign: CampaignSummaryStats = this.props.campaign;
        const data = {
            style: {
                borderColor: this.props.color
            }
        };

        const isBucketAffiliate = this.props.user.broker.bucketUsedCount;

        return (
            <>
                <tr
                    className={`${this.props.rowClass ? this.props.rowClass : ''}`}
                    onClick={() => {
                        this.setState({expanded: !this.state.expanded})
                    }}
                    style={{
                        color: this.props.color
                    }}
                >
                    {campaign.isSubCampaign ?
                        <td {...data}>{campaign.campaignName !== null && campaign.campaignName.length > 0 ? campaign.campaignName : APP_STRINGS.campaigns.table.noName}</td>

                        :
                        <td {...data}>{campaign.campaignId}</td>
                    }
                    <td {...data}/>


                    {!isBucketAffiliate &&
                        <>
                            <td {...data}>{FormatData(campaign, StatsChartYCol.AvgPostCost)}</td>
                            <td {...data}>{FormatData(campaign, StatsChartYCol.AvgAcceptCost)}</td>
                        </>
                    }

                    <td {...data}>{FormatData(campaign, StatsChartYCol.Cost)}</td>
                    <td {...data}>{FormatData(campaign, StatsChartYCol.Clicks)}</td>

                    {/*{isBucketAffiliate &&*/}
                        {/*<td {...data}>{FormatData(campaign, StatsChartYCol.PostbackFires)}</td>*/}
                    {/*}*/}

                    {!isBucketAffiliate &&
                        <>
                            <td {...data}>{FormatData(campaign, StatsChartYCol.Posts)}</td>
                            <td {...data}>{FormatData(campaign, StatsChartYCol.PostsAccepted)}</td>
                            <td {...data}>{FormatData(campaign, StatsChartYCol.PostsRejected)}</td>
                            <td {...data}>{FormatData(campaign, StatsChartYCol.PostsErrors)}</td>
                            <td {...data}>{FormatData(campaign, StatsChartYCol.PostsAcceptedPercentage)}</td>
                            <td {...data}>{FormatData(campaign, StatsChartYCol.ClicksAcceptedPercentage)}</td>
                            <td {...data}>{FormatData(campaign, StatsChartYCol.SuccessfulRedirects)}</td>
                        </>
                    }
                    <td {...data}>{FormatData(campaign, StatsChartYCol.RedirectRate)}</td>
                </tr>
                {(this.state.expanded && this.props.subCampaigns) && this.props.subCampaigns.map((campaign: CampaignSummaryStats, index: number) => {
                    return (
                            <CampaignRow
                                user={this.props.user}
                                key={`sub-campaign-${index}-${campaign.campaignId}`}
                                campaign={campaign}
                                subCampaigns={[]}
                                color={this.props.color}
                                rowClass={'subid'}
                            />
                    );
                })}
            </>
        );
    }
}
