import Axios, {AxiosError, AxiosInstance, AxiosResponse} from "axios";
import {IHttpRequester} from "./Interfaces/IHttpRequester";
import {HttpError, HttpHeaders, HttpSuccess} from "./Types";
const https = require('https');

const DEFAULT_AXIOS_HEADERS = {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

export class HttpRequester implements IHttpRequester {

    private baseUrl: string;
    private defaultHeaders: HttpHeaders;

    private _token: string|null = null;

    private _dev: boolean = false;

    private axios: AxiosInstance;

    constructor(baseUrl: string, defaultHeaders: HttpHeaders) {
        this.baseUrl = baseUrl;
        this.defaultHeaders = defaultHeaders;

        this.axios = Axios.create({
            headers: DEFAULT_AXIOS_HEADERS
        });
    }

    Headers() {
        return this.axios.defaults.headers;
    }

    setDev(dev: boolean) {
        this._dev = dev;
        this.axios.defaults.httpsAgent = new https.Agent({
            rejectUnauthorized: !this._dev,
        });
    }

    setToken(token: string|null) {
        this._token = token;

        if (this._token) {
            this.axios.defaults.headers = {
                ...DEFAULT_AXIOS_HEADERS,
                'Cookie': `PHPSESSID=${this._token}`,
            };
        }
        else {
            this.axios.defaults.headers = DEFAULT_AXIOS_HEADERS;
        }
    }

    get(url: string, withCredentials: boolean = true): Promise<any> {
        return new Promise<any>((resolve: (data: HttpSuccess) => void, reject: (error: HttpError) => void) => {

            this.axios.get(this.baseUrl + url, {
                withCredentials: withCredentials,
            }).then((data: AxiosResponse) => {
                resolve(this.toSuccess(data));
            }).catch((error: AxiosError) => {
                reject(this.toError(error));
            });
        });
    }

    put(url: string, data: any, withCredentials: boolean = true): Promise<any> {

        return new Promise<any>((resolve: (data: HttpSuccess) => void, reject: (error: HttpError) => void) => {

            this.axios.put(this.baseUrl + url, data, {
                withCredentials: withCredentials,
            }).then((data: AxiosResponse) => {
                resolve(this.toSuccess(data));
            }).catch((error: AxiosError) => {
                reject(this.toError(error));
            });
        });
    }

    post(url: string, data: any, withCredentials: boolean = true): Promise<any> {

        return new Promise<any>((resolve: (data: HttpSuccess) => void, reject: (error: HttpError) => void) => {

            this.axios.post(this.baseUrl + url, data, {
                withCredentials: withCredentials,
            }).then((data: AxiosResponse) => {
                resolve(this.toSuccess(data));
            }).catch((error: AxiosError) => {
                reject(this.toError(error));
            });
        });
    }

    postFile(url: string, data: FormData, withCredentials: boolean = true, additionalHeaders?: any): Promise<any> {

        return new Promise<any>((resolve: (data: HttpSuccess) => void, reject: (error: HttpError) => void) => {

            let headers = this.axios.defaults.headers;
            delete headers['Content-Type'];

            if (additionalHeaders) {
                for (const key of Object.keys(additionalHeaders)) {
                    headers[key] = additionalHeaders[key];
                }
            }
            else {
                headers['Content-Type'] = 'multipart/form-data';
            }

            this.axios.post(this.baseUrl + url, data, {
                withCredentials: withCredentials,
                headers: headers
            }).then((data: AxiosResponse) => {
                resolve(this.toSuccess(data));
            }).catch((error: AxiosError) => {
                reject(this.toError(error));
            });
        });
    }

    delete(url: string, withCredentials: boolean = true): Promise<any> {
        return new Promise<any>((resolve: (data: HttpSuccess) => void, reject: (error: HttpError) => void) => {

            this.axios.delete(this.baseUrl + url, {
                withCredentials: withCredentials,
            }).then((data: AxiosResponse) => {
                resolve(this.toSuccess(data));
            }).catch((error: AxiosError) => {
                reject(this.toError(error));
            });
        });
    }

    private toSuccess = (response: AxiosResponse): HttpSuccess => {
        return {
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }
    };

    private toError = (error: AxiosError): HttpError => {
        return {
            error: error,
            response: error.response ? error.response.data : null,
            statusCode: error.response ? error.response.status : 0,
        }
    };
}
