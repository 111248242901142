import * as React from "react";

type Props = {
    children: JSX.Element,
}

const AnonNavigation = (args: Props) => {

    return (

        <div id="anon-wrapper" className={'columns'}>

            <div className={'column is-full'}>

                <div className={'anon-form-wrapper columns is-centered is-vcentered'}>
                    <div className={'anon-form column is-one-third-desktop is-half-tablet is-full-mobile'}>
                        {args.children}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AnonNavigation;