import * as React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./Login";
import ForgottenPassword from "./ForgottenPassword";
import CampaignReports from "./Campaigns/CampaignReports";
import Dashboard from "./Dashboard/Dashboard";
import EditAccount from "./EditAccount/EditAccount";
import {PortalContainerProps, PortalContainerState} from "../Containers/PortalContainer";
import NavigationWrapper from "./Navigation/NavigationWrapper";
import {LoadingState} from "../types";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faChartLine, faHome, faSignOutAlt, faUser, faEnvelope, faLock, faList } from '@fortawesome/free-solid-svg-icons';
import AnonNavigation from "./Navigation/AnonNavigation";
import {DASHBOARD_ROUTE, REPORTS_ROUTE, APPLICATION_ROUTE} from "../Constants";
import { showApplicationPage, showDashboardPage } from "../branding";
import Applications from "./Applications/Applications";

library.add(faSpinner);
library.add(faChartLine);
library.add(faHome);
library.add(faSignOutAlt);
library.add(faUser);
library.add(faEnvelope);
library.add(faLock);
library.add(faList);


const PrivateRoute = ({ loggedIn, ...rest }) => (
    loggedIn ?
        <Route {...rest} />
    :
        <Redirect
            to={{
                pathname: "/"
            }}
        />
);

export default class App extends React.Component<PortalContainerState & PortalContainerProps> {

    render() {
        const loggedIn = this.props.user !== null;
        const logoutState = this.props.logoutState ? this.props.logoutState : {state: LoadingState.Nothing, error: null};

        return <HashRouter basename="/">
            <Switch>
                {/*<Route exact path="/" render={() => {*/}
                    {/*return <Splash/>*/}
                {/*}} />*/}
                <Route exact path="/" render={() => {
                    return (
                        <AnonNavigation>
                            <Login
                                language={this.props.language}
                                updateLanguageAction={this.props.updateLanguageAction}
                                loggedIn={loggedIn}
                                login={this.props.login}
                                loginState={this.props.loginState ? this.props.loginState : {state: LoadingState.Nothing, error: null}}
                            />
                        </AnonNavigation>
                    )
                }} />
                <Route exact path="/forgotten-password" render={() => {
                    return (
                        <AnonNavigation>
                            <ForgottenPassword/>
                        </AnonNavigation>
                    )
                }} />
                <PrivateRoute loggedIn={loggedIn} exact path="/edit-account" render={() => {
                    return (
                        <NavigationWrapper
                            language={this.props.language}
                            updateLanguageAction={this.props.updateLanguageAction}
                            logoutState={logoutState}
                            logout={this.props.logout}
                        >
                            <EditAccount
                                user={this.props.user}
                                passwordUpdateState={this.props.passwordUpdateState ? this.props.passwordUpdateState : {state: LoadingState.Nothing, error: null}}
                                updatePassword={this.props.updatePassword}
                            />
                        </NavigationWrapper>
                    );
                }} />

                {showDashboardPage() &&
                    <PrivateRoute
                        loggedIn={loggedIn}
                        exact path={DASHBOARD_ROUTE}
                        render={() => {
                            return (
                                <NavigationWrapper
                                    language={this.props.language}
                                    updateLanguageAction={this.props.updateLanguageAction}
                                    logoutState={logoutState}
                                    logout={this.props.logout}
                                >
                                    <Dashboard
                                        user={this.props.user}
                                        overallStatsSpanAmount={this.props.overallStatsSpanAmount ? this.props.overallStatsSpanAmount : 1}
                                        setDashboardSpanAmount={this.props.setDashboardSpanAmount}
                                        summary={this.props.summary}
                                        stats={this.props.overallStats}
                                        summaryState={this.props.summaryState ? this.props.summaryState : {
                                            state: LoadingState.Nothing,
                                            error: null
                                        }}
                                        overallStatsState={this.props.overallStatsState ? this.props.overallStatsState : {
                                            state: LoadingState.Nothing,
                                            error: null
                                        }}
                                        fetchSummary={this.props.fetchSummary}
                                        fetchStats={this.props.fetchOverallStatsAction}
                                    />
                                </NavigationWrapper>
                            );
                        }}
                    />
                }

                {showApplicationPage() &&
                    <PrivateRoute
                        loggedIn={loggedIn}
                        exact path={APPLICATION_ROUTE}
                        render={() => {
                            return (
                                <NavigationWrapper
                                    language={this.props.language}
                                    updateLanguageAction={this.props.updateLanguageAction}
                                    logoutState={logoutState}
                                    logout={this.props.logout}
                                >
                                    <Applications
                                        loading={this.props.loadingApplications}
                                        applications={this.props.applications}
                                        getApplications={this.props.getApplications}
                                    />
                                </NavigationWrapper>
                            );
                        }}
                    />
                }
                <PrivateRoute
                    loggedIn={loggedIn}
                    exact path={REPORTS_ROUTE}
                    render={() => {
                        return (
                            <NavigationWrapper
                                language={this.props.language}
                                updateLanguageAction={this.props.updateLanguageAction}
                                logoutState={logoutState}
                                logout={this.props.logout}
                            >
                                <CampaignReports
                                    user={this.props.user}
                                    overallStats={this.props.campaignOverallStats}
                                    campaignSummaryState={this.props.campaignSummaryState ? this.props.campaignSummaryState : {state: LoadingState.Nothing, error: null}}
                                    allCampaignOverallStats={this.props.allCampaignOverallStats}
                                    allCampaignSummaryState={this.props.allCampaignSummaryState ? this.props.allCampaignSummaryState : {state: LoadingState.Nothing, error: null}}
                                    allCampaignDailyStats={this.props.allCampaignDailyStats}
                                    allCampaignDailyStatsState={this.props.allCampaignDailyStatsState ? this.props.allCampaignDailyStatsState : {state: LoadingState.Nothing, error: null}}
                                    fetchCampaignReports={this.props.fetchCampaignReports}
                                    fetchAllCampaignReportsAction={this.props.fetchAllCampaignReportsAction}
                                    fetchAllCampaignDailyStatsAction={this.props.fetchAllCampaignDailyStatsAction}
                                />
                            </NavigationWrapper>
                        );
                }} />
            </Switch>
        </HashRouter>
    }
}
