import * as React from "react";
import {Moment} from "moment";
import {APP_STRINGS} from "../../types";
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import {IsMobile} from "../../utils";
import {primaryButtonClass} from "../../branding";

type Props = {
    start: Moment,
    end: Moment,
    isLoading: boolean,
    onSearch: (start: Moment, end: Moment) => void,
}

type State = {
    start: Moment,
    end: Moment,
    focusedInput: any,
}

export default class Search extends React.Component<Props, State> {

    state = {
        start: this.props.start,
        end: this.props.end,
        focusedInput: null,
    };

    componentWillReceiveProps(nextProps: Props) {
        this.setState({
            start: nextProps.start,
            end: nextProps.end,
        })
    }

    render() {

        const args = this.props;

        return (
            <div className="reports-search">

                <div className={'field'}>
                    <div className={'control'}>
                        <DateRangePicker
                            startDate={this.state.start}
                            endDate={this.state.end}
                            startDateId={'search-start-date'}
                            endDateId={'search-end-date'}
                            focusedInput={this.state.focusedInput}
                            onDatesChange={({ startDate, endDate }) => this.setState({ start: startDate, end: endDate })}
                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                            isOutsideRange={day => {return false}}
                            disabled={args.isLoading}
                            orientation={IsMobile() ? 'vertical' : 'horizontal'}
                            small={true}
                        />
                    </div>
                </div>
                <button
                    className={`button ${primaryButtonClass()} ${args.isLoading ? 'is-loading' : ''}`}
                    onClick={() => {
                        console.log('click');
                        args.onSearch(this.state.start, this.state.end);
                    }}
                    disabled={args.isLoading}
                >
                    {APP_STRINGS.campaigns.searchBtnLabel}
                </button>
            </div>
        )
    }
}
