import * as React from "react";
import CampaignRow from "./CampaignRow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {APP_STRINGS, LoadingState, NetworkState} from "../../types";
import CampaignTile from "./CampaignTile";
import { sortBy } from "lodash";
import {CampaignSummaryStats, User} from "signature-public-api-sdk";

type Props = {
    user: User,
    campaigns: CampaignSummaryStats[],
    allCampaignDailyStatsState: NetworkState,
    lineColours: string[],
}

const Campaigns: React.ComponentType<Props> = (args: Props) => {

    let lineColourCounterTr = 0;
    let lineColourCounter = 0;

    let campaigns = args.campaigns ? args.campaigns : null;

    if (campaigns) {
        campaigns = sortBy(campaigns, (stat: CampaignSummaryStats) => {return stat.campaignId})
    }

    const isBucketAffiliate = args.user.broker.bucketUsedCount;

    return (
        <div id="report-campaigns">
            <h2 className={'is-flex'}>
                {APP_STRINGS.campaigns.table.title}
                {args.allCampaignDailyStatsState.state === LoadingState.Pending &&
                    <>
                        &nbsp;<FontAwesomeIcon icon={'spinner'} pulse={true} className={'align-self-center'}/>
                    </>
                }
            </h2>
            <div className="is-hidden-mobile is-hidden-tablet-only">
                <table>
                    <thead>
                    <tr>
                        <th>{APP_STRINGS.campaigns.table.campaign}</th>
                        <th>{APP_STRINGS.campaigns.table.active}</th>

                        {!isBucketAffiliate &&
                            <>
                                <th>{APP_STRINGS.campaigns.table.payoutPerPost}</th>
                                <th>{APP_STRINGS.campaigns.table.payoutPerAccept}</th>
                            </>
                        }

                        <th>{APP_STRINGS.campaigns.table.payout}</th>
                        <th>{APP_STRINGS.campaigns.table.clicks}</th>

                        {/*{isBucketAffiliate &&*/}
                            {/*<th>{APP_STRINGS.campaigns.table.postbackFires}</th>*/}
                        {/*}*/}

                        {!isBucketAffiliate &&
                            <>
                                <th>{APP_STRINGS.campaigns.table.posts}</th>
                                <th>{APP_STRINGS.campaigns.table.postsAccepted}</th>
                                <th>{APP_STRINGS.campaigns.table.postsRejected}</th>
                                <th>{APP_STRINGS.campaigns.table.postsErrors}</th>
                                <th>{APP_STRINGS.campaigns.table.postsAcceptedPercentage}</th>
                                <th>{APP_STRINGS.campaigns.table.clicksAcceptedPercentage}</th>
                                <th>{APP_STRINGS.campaigns.table.successfulRedirects}</th>
                            </>
                        }

                        <th>{APP_STRINGS.campaigns.table.redirectRatePercentage}</th>
                    </tr>
                    </thead>
                    <tbody>
                        {args.campaigns && campaigns.map((campaign: CampaignSummaryStats, index: number) => {

                            if (campaign.isSubCampaign)
                                return null;

                            const colour = args.lineColours[lineColourCounterTr];
                            lineColourCounterTr++;

                            return(
                                <CampaignRow
                                    user={args.user}
                                    key={`campaign-row-${index}`}
                                    campaign={campaign}
                                    subCampaigns={args.campaigns.filter((camp: CampaignSummaryStats) => {
                                        return camp.isSubCampaign && camp.campaignId === campaign.campaignId;
                                    })}
                                    color={colour}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="campaign-tiles columns is-mobile is-centered is-multiline is-hidden-desktop is-hidden-widescreen is-hidden-fullhd">
                {campaigns && campaigns.map((campaign: CampaignSummaryStats, index: number) => {

                    if (campaign.isSubCampaign)
                        return null;

                    const colour = args.lineColours[lineColourCounter];
                    lineColourCounter++;

                    return (
                        <CampaignTile
                            user={args.user}
                            key={`campaign-tile-${index}`}
                            campaign={campaign}
                            subCampaigns={args.campaigns.filter((camp: CampaignSummaryStats) => {
                                return camp.isSubCampaign && camp.campaignId === campaign.campaignId;
                            })}
                            colour={colour}
                        />
                    )
                })}
            </div>
        </div>

    );
};

export default Campaigns;