import {IHttpRequester} from "../../Interfaces/IHttpRequester";
import {HttpError, HttpSuccess, LoanApiEndpoints} from "../../Types";
import {LoginResponse, MeResponse} from "../../ResponseTypes";

export class AffiliateUsers {

    private endpoints: LoanApiEndpoints;
    private requester: IHttpRequester;

    constructor(endpoints: LoanApiEndpoints, requester: IHttpRequester) {
        this.endpoints = endpoints;
        this.requester = requester;
    }

    /**
     * Get basic information about the currently authenticated user.
     */
    public me(): Promise<MeResponse> {

        return new Promise<MeResponse>((resolve: (data: MeResponse) => void, reject: (error: HttpError|null) => void) => {
            this.requester.get(this.endpoints.me).then((success: HttpSuccess) => {
                if (success.data.id)
                    resolve(success.data);
                else
                    reject(null);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }

    /**
     * Update The current user's password.
     * @param currentPassword
     * @param newPassword
     */
    public updatePassword(currentPassword: string, newPassword: string): Promise<null> {

        return new Promise((resolve: () => void, reject: (error: HttpError|null) => void) => {
            this.requester.put(this.endpoints.updatePassword, {password: currentPassword, newPassword: newPassword}).then((success: HttpSuccess) => {
                resolve();
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }
}
