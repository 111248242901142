import * as React from "react";
import {FormatData} from "./FormatData";
import {APP_STRINGS, StatsChartYCol} from "../../types";
import {CampaignSummaryStats, User} from "signature-public-api-sdk";

type Props = {
    user: User,
    campaign: CampaignSummaryStats,
    subCampaigns: CampaignSummaryStats[],
    colour: string,
}

type State = {
    expanded: boolean
}

export default class CampaignTile extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            expanded: false
        };
    }

    render() {

        const campaign: CampaignSummaryStats = this.props.campaign;
        const isBucketAffiliate = this.props.user.broker.bucketUsedCount;

        return (
            <>
                <div
                    className={`tile column columns is-mobile is-multiline ${campaign.isSubCampaign ? 'subid is-11' : 'is-12'}`}
                    style={{borderColor: this.props.colour}}
                    onClick={() => {
                        this.setState({expanded: !this.state.expanded})
                    }}
                >
                    {this.info(APP_STRINGS.campaigns.table.campaign,
                        campaign.isSubCampaign ?
                            campaign.campaignName !== null && campaign.campaignName.length > 0 ? campaign.campaignName : APP_STRINGS.campaigns.table.noName

                            :
                            campaign.campaignId
                    )}
                    {this.info(APP_STRINGS.campaigns.table.active, '')}

                    {!isBucketAffiliate &&
                        <>
                            {this.info(APP_STRINGS.campaigns.table.payoutPerPost, FormatData(campaign, StatsChartYCol.AvgPostCost))}
                            {this.info(APP_STRINGS.campaigns.table.payoutPerAccept, FormatData(campaign, StatsChartYCol.AvgAcceptCost))}
                        </>
                    }

                    {this.info(APP_STRINGS.campaigns.table.payout, FormatData(campaign, StatsChartYCol.Cost))}
                    {this.info(APP_STRINGS.campaigns.table.clicks, FormatData(campaign, StatsChartYCol.Clicks))}
                    {this.info(APP_STRINGS.campaigns.table.postbackFires, FormatData(campaign, StatsChartYCol.PostbackFires))}


                    {!isBucketAffiliate &&
                        <>
                            {this.info(APP_STRINGS.campaigns.table.posts, FormatData(campaign, StatsChartYCol.Posts))}
                            {this.info(APP_STRINGS.campaigns.table.postsAccepted, FormatData(campaign, StatsChartYCol.PostsAccepted))}
                            {this.info(APP_STRINGS.campaigns.table.postsRejected, FormatData(campaign, StatsChartYCol.PostsRejected))}
                            {this.info(APP_STRINGS.campaigns.table.postsErrors, FormatData(campaign, StatsChartYCol.PostsErrors))}
                            {this.info(APP_STRINGS.campaigns.table.postsAcceptedPercentage, FormatData(campaign, StatsChartYCol.PostsAcceptedPercentage))}
                            {this.info(APP_STRINGS.campaigns.table.clicksAcceptedPercentage, FormatData(campaign, StatsChartYCol.ClicksAcceptedPercentage))}
                            {this.info(APP_STRINGS.campaigns.table.successfulRedirects, FormatData(campaign, StatsChartYCol.SuccessfulRedirects))}
                        </>
                    }

                    {this.info(APP_STRINGS.campaigns.table.redirectRatePercentage, FormatData(campaign, StatsChartYCol.RedirectRate))}
                </div>
                {(this.state.expanded && this.props.subCampaigns) && this.props.subCampaigns.map((campaign: CampaignSummaryStats, index: number) => {
                    return (
                        <CampaignTile
                            user={this.props.user}
                            key={`sub-campaign-${index}-${campaign.campaignId}`}
                            campaign={campaign}
                            subCampaigns={[]}
                            colour={this.props.colour}
                        />
                    );
                })}
            </>
        );
    }

    private info = (title: string, value: any) => {
        return (
            <div className={'column is-one-third-mobile is-one-quarter-tablet'}>
                <div className="stat">
                    {title}
                </div>
                <div className="value">
                    {value}
                </div>
            </div>
        )
    }
}
