import * as React from "react";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {SYSTEM_DATE_FORMAT} from "../../Constants";
import {APP_STRINGS, ChartData, OverallStatsSpan, StatsChartYCol, StatsChartYColLabels} from "../../types";
import moment = require("moment");
import CustomComparisonTooltip from "./CustomComparisonTooltip";
import {Moment} from "moment";
import {DailyStat, TimeOverallStatsResponse, User} from "signature-public-api-sdk";
import {chartCurrentPeriodColour, chartPreviousPeriodColour} from "../../branding";

type Props = {
    user: User,
    stats: TimeOverallStatsResponse,
    overallStatsSpan: OverallStatsSpan,
    overallStatsSpanAmount: number,

    lineKey: StatsChartYCol,
}

const SIZES = {
    [OverallStatsSpan.Months]: 31,
};

export default class Graph extends React.Component<Props> {

    render() {

        const currentStart = moment().startOf('month').subtract((this.props.overallStatsSpanAmount - 1), this.props.overallStatsSpan);
        let merged = this.arraySize(((SIZES[this.props.overallStatsSpan] * this.props.overallStatsSpanAmount) * 2), currentStart, this.props.user);

        if (this.props.stats) {
            const previousStart = currentStart.clone().subtract(this.props.overallStatsSpanAmount, 'months');

            const currentData: ChartData[] = this.chartData(this.props.stats.current, currentStart, '', 'dailyStatCurrent', this.props.user);
            const previousData: ChartData[] = this.chartData(this.props.stats.previous, previousStart, '2', 'dailyStatPrevious', this.props.user);
            merged = this.mergeChartData(currentData, previousData);
        }

        const yLabel = StatsChartYColLabels()[this.props.lineKey];

        return(
            <ResponsiveContainer>
                <LineChart
                    data={merged}
                    margin={{ top: 10, right: 10, left: 10, bottom: 50 }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>

                    <XAxis label={{ value: APP_STRINGS.charts.date, position: 'bottom' }} dataKey="key" />
                    <YAxis label={{ value: yLabel, angle: -90, position: 'insideLeft' }} />
                    <Tooltip content={<CustomComparisonTooltip/>}/>
                    {/*<CartesianGrid stroke="#f5f5f5" />*/}
                    <Line dataKey={this.props.lineKey} stroke={chartCurrentPeriodColour()} strokeWidth={3} />
                    <Line dataKey={`${this.props.lineKey}2`} stroke={chartPreviousPeriodColour()} strokeDasharray="3 3" strokeWidth={3} />
                </LineChart>
            </ResponsiveContainer>
        );
    }

    private mergeChartData = (current: ChartData[], prev: ChartData[]): ChartData[] => {

        const merged: ChartData[] = JSON.parse(JSON.stringify(current));

        for (const i in prev) {
            const two = prev[Number(i)];
            merged[Number(i)].cost2 = two.cost2;
            merged[Number(i)].clicks2 = two.clicks2;
            merged[Number(i)].epc2 = two.epc2;
            merged[Number(i)].rpt2 = two.rpt2;
            merged[Number(i)].posts2 = two.posts2;
            merged[Number(i)].postbackFires2 = two.postbackFires2;
            merged[Number(i)].postsAccepted2 = two.postsAccepted2;
            merged[Number(i)].dailyStatPrevious = JSON.parse(JSON.stringify(two.dailyStatPrevious));
        }

        return merged;
    };

    private chartData = (stats: DailyStat[], start: Moment, statKeySuffix: string, statKey: string, user: User): ChartData[] => {

        const array: ChartData[] = this.arraySize(SIZES[this.props.overallStatsSpan] * this.props.overallStatsSpanAmount, start, user);
        const graphKeys = Object.keys(StatsChartYCol).map((key: string) => {return StatsChartYCol[key]});
        const chunkSize = SIZES[this.props.overallStatsSpan];

        for (const i in stats) {

            const stat: DailyStat = stats[i];

            const date = moment(stat.date, SYSTEM_DATE_FORMAT);
            const monthDiff = Math.abs(date.diff(start, this.props.overallStatsSpan));
            const arrayKey = (date.date() - 1) + (monthDiff > 0 ? (monthDiff * chunkSize) - 1 : 0);

            for (const key of graphKeys) {

                if (stat[key] !== undefined)
                    array[arrayKey][`${key}${statKeySuffix}`] = stat[key];
            }

            // array[date.date() - 1][amountKey] = stat.cost;
            array[arrayKey][statKey] = JSON.parse(JSON.stringify(stat));
        }

        return array;

    };

    private arraySize = (size: number, startMoment: Moment, user: User): ChartData[] => {

        const array: ChartData[] = [];
        const start = startMoment.clone().startOf('month');

        let dayTicker = 1;

        for (let i = 0; i < size; i++) {
            array.push({
                cost: 0,
                cost2: 0,

                clicks: 0,
                clicks2: 0,

                epc: 0,
                epc2: 0,

                rpt: 0,
                rpt2: 0,

                posts: 0,
                posts2: 0,

                postbackFires: 0,
                postbackFires2: 0,

                postsAccepted: 0,
                postsAccepted2: 0,

                amount: 0,
                amount2: 0,
                key: `${numeral(dayTicker).format('00')}-${start.format('MM')}`,
                date: `${start.format('YYYY-MM')}-${numeral(dayTicker).format('00')}`,
                dailyStatCurrent: null,
                dailyStatPrevious: null,
                user: user,
            });

            dayTicker++;

            if (dayTicker > 31) {
                dayTicker = 1;
                start.add(1, "month");
            }
        }

        return array;
    }
}
