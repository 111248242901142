import * as React from "react";
import {TooltipProps} from "recharts";
import {APP_STRINGS, StatsChartYCol, StatsChartYColLabels} from "../../types";
import {FormatData} from "./FormatData";
import {CampaignSummaryStats} from "signature-public-api-sdk";

type Props = TooltipProps | any | {
    chartsValueKey: StatsChartYCol,
};

const PerformancePieChartTooltip = (args: Props) => {

    if (Array.isArray(args.payload) && args.payload.length > 0) {

        if (args.payload[0].payload && args.payload[0].payload.payload) {

            const stats: CampaignSummaryStats = args.payload[0].payload.payload;

            return (
                <div className={'custom-tooltip campaign-performance-tooltip'}>
                    <div className={'campaign-name'}>
                        {Object.keys(stats).length === 1 ?
                            <>
                                {APP_STRINGS.campaigns.noSubId}
                            </>

                            :

                            <>
                                {(stats.campaignName === undefined ||stats.campaignName === null || stats.campaignName.length === 0) ?
                                    APP_STRINGS.campaigns.noSubIdName
                                    :
                                    stats.isSubCampaign ? stats.campaignName : stats.campaignId
                                }
                            </>
                        }
                    </div>
                    <div className={'value'}>
                        {StatsChartYColLabels()[args.chartsValueKey]}: {FormatData(stats, args.chartsValueKey)}
                    </div>
                </div>
            )
        }
    }

    return null;
};

export default PerformancePieChartTooltip;