import {HttpRequester} from "./HttpRequester";
import {LoanApiEndpoints} from "./Types";
import {DEFAULT_API_ENDPOINTS} from "./Defaults";
import {IHttpRequester} from "./Interfaces/IHttpRequester";
import { Auth } from "./Endpoints/Auth";
import { AffiliatePortal } from "./Endpoints/AffiliatePortal/AffiliatePortal";
import { ClientPortal } from "./Endpoints/ClientPortal/ClientPortal";

export class API {

    private readonly _baseUrl: string;
    private readonly _endpoints: LoanApiEndpoints;
    private readonly _requester: IHttpRequester;

    private readonly _auth: Auth;
    private readonly _affiliatePortal: AffiliatePortal;
    private readonly _clientPortal: ClientPortal;

    private _token: string|null = null;

    constructor(baseUrl: string, endpoints?: LoanApiEndpoints, requester?: IHttpRequester) {

        this._baseUrl = baseUrl;
        this._endpoints = endpoints ? endpoints : DEFAULT_API_ENDPOINTS;

        if (!requester)
            this._requester = new HttpRequester(this._baseUrl,{

            });
        else
            this._requester = requester;

        this._auth = new Auth(this._endpoints, this._requester);
        this._affiliatePortal = new AffiliatePortal(this._endpoints, this._requester);
        this._clientPortal = new ClientPortal(this._endpoints, this._requester);
    }

    set Token(cookie: string|null) {
        this._token = cookie;
        this._requester.setToken(this._token);
    }

    get Token() {
        return this._token;
    }

    get BaseUrl() {
        return this._baseUrl;
    }

    get Requester() {
        return this._requester;
    }

    get Auth() {
        return this._auth;
    }

    get AffiliatePortal() {
        return this._affiliatePortal;
    }

    get ClientPortal() {
        return this._clientPortal;
    }

    get Endpoints() {
        return this._endpoints;
    }

    static get DefaultEndpoints() {
        return DEFAULT_API_ENDPOINTS;
    }
}
