import * as React from "react";
import {SyntheticEvent} from "react";
import {User} from "signature-public-api-sdk";
import {APP_STRINGS, LoadingState, NetworkState, UpdatePasswordAction} from "../../types";
import {primaryButtonClass} from "../../branding";

type Props = {
    user: User,
    passwordUpdateState: NetworkState,
    updatePassword: UpdatePasswordAction,
}

type State = {
    currentPassword: string,
    newPassword: string,
    newPasswordConfirm: string,
    changedPasswordSuccess: boolean,
}

class EditAccount extends React.Component<Props, State> {

    state = {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
        changedPasswordSuccess: false,
    };

    private timer = null;

    componentWillReceiveProps(nextProps: Props) {

        if (this.props.passwordUpdateState.state === LoadingState.Pending && nextProps.passwordUpdateState.state === LoadingState.Nothing) {
            this.setState({
                changedPasswordSuccess: true,
                currentPassword: '',
                newPassword: '',
                newPasswordConfirm: '',
            }, () => {

                this.timer = setTimeout(() => {

                    this.timer = null;

                    this.setState({
                        changedPasswordSuccess: false,
                    });
                }, 5000);
            });
        }
    }

    componentWillUnmount() {

        if (this.timer !== null)
            clearTimeout(this.timer);
    }

    render() {

        const failed = this.props.passwordUpdateState.state === LoadingState.Error;
        const isLoading = this.props.passwordUpdateState.state === LoadingState.Pending;
        const disablePasswordInputs = this.props.passwordUpdateState.state === LoadingState.Pending;

        const anyPasswordInputFilled = this.state.currentPassword.length > 0 || this.state.newPassword.length > 0 || this.state.newPasswordConfirm.length > 0;
        const passwordsMatch = this.state.newPassword === this.state.newPasswordConfirm;

        const minLength = passwordsMatch && this.state.newPassword.length >= 6;
        const currentPassword = this.state.currentPassword.length > 0;
        const disableFormSubmit = disablePasswordInputs || !passwordsMatch || !minLength || !currentPassword || !anyPasswordInputFilled;

        return (
            <div id="edit-account">
                <h1>{APP_STRINGS.myAccount.title}</h1>
                <div className={'columns is-mobile'}>
                    <div className="column is-full-mobile is-one-third-tablet is-one-third-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                        <div className={'field'}>
                            <div className={'control'}>
                                <label>{APP_STRINGS.myAccount.companyName}</label>
                                <input
                                    disabled={true}
                                    className={'input'}
                                    type="text"
                                    placeholder={APP_STRINGS.myAccount.companyName}
                                    value={this.props.user ? this.props.user.broker.name : null}
                                />
                            </div>
                        </div>
                        <div className={'field'}>
                            <div className={'control'}>
                                <label>{APP_STRINGS.myAccount.username}</label>
                                <input
                                    disabled={true}
                                    className={'input'}
                                    type="text"
                                    placeholder={APP_STRINGS.myAccount.username}
                                    value={this.props.user ? this.props.user.email : null}
                                />
                            </div>
                        </div>

                        <h2>{APP_STRINGS.myAccount.updatePasswordTitle}</h2>

                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (!isLoading && !disableFormSubmit) {
                                    this.props.updatePassword(String(this.state.currentPassword), String(this.state.newPassword));
                                }
                            }}
                        >

                            <div className={'field'}>
                                <div className={'control'}>
                                    <label>{APP_STRINGS.myAccount.currentPassword}</label>
                                    <input
                                        required
                                        disabled={disablePasswordInputs}
                                        className={'input'}
                                        type="password"
                                        placeholder={APP_STRINGS.myAccount.currentPassword}
                                        value={this.state.currentPassword}
                                        onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                                            this.setState({
                                                currentPassword: e.currentTarget.value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                            <div className={'field'}>
                                <div className={'control'}>
                                    <label>{APP_STRINGS.myAccount.newPassword}</label>
                                    <input
                                        required
                                        disabled={disablePasswordInputs}
                                        className={'input'}
                                        type="password"
                                        placeholder={APP_STRINGS.myAccount.newPassword}
                                        value={this.state.newPassword}
                                        onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                                            this.setState({
                                                newPassword: e.currentTarget.value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                            <div className={'field'}>
                                <div className={'control'}>
                                    <label>{APP_STRINGS.myAccount.confirmNewPassword}</label>
                                    <input
                                        required
                                        disabled={disablePasswordInputs}
                                        className={'input'}
                                        type="password"
                                        placeholder={APP_STRINGS.myAccount.confirmNewPassword}
                                        value={this.state.newPasswordConfirm}
                                        onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                                            this.setState({
                                                newPasswordConfirm: e.currentTarget.value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                            {(!currentPassword && anyPasswordInputFilled) &&
                                <div>
                                    {APP_STRINGS.myAccount.enterCurrentPassword}
                                </div>
                            }

                            {(!passwordsMatch && anyPasswordInputFilled) &&
                                <div>
                                    {APP_STRINGS.myAccount.newPasswordsMustMatch}
                                </div>
                            }

                            {(!minLength && anyPasswordInputFilled) &&
                                <div>
                                    {APP_STRINGS.myAccount.newPasswordsMinLength}
                                </div>
                            }

                            <button
                                className={`button ${this.state.changedPasswordSuccess ? 'is-success' : primaryButtonClass()} ${isLoading ? 'is-loading' : ''}`}
                                type="submit"
                                disabled={disableFormSubmit}
                            >
                                {this.state.changedPasswordSuccess ? APP_STRINGS.myAccount.updatePasswordSuccessSubmitButton : APP_STRINGS.myAccount.updatePasswordSubmitButton}
                            </button>

                            {failed &&
                                <div>
                                    {APP_STRINGS.myAccount.failedPasswordUpdate}
                                </div>

                            }
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditAccount;