import {IHttpRequester} from "../../Interfaces/IHttpRequester";
import { ApplicationExpandedData, HttpError, HttpSuccess, LoanApiEndpoints } from "../../Types";
import { ApplicationNeed, ApplicationList, NextActionDate, Application } from "../../ResponseTypes";

import * as URI from "urijs";
require("urijs/src/URITemplate");

export class ClientApplications {

    private endpoints: LoanApiEndpoints;
    private requester: IHttpRequester;

    constructor(endpoints: LoanApiEndpoints, requester: IHttpRequester) {
        this.endpoints = endpoints;
        this.requester = requester;
    }

    /**
     *
     * @param limit
     * @param next
     * @param withData Ask for extra data
     */
    public list(limit: number = 5, next?: number, withData?: ApplicationExpandedData[]): Promise<ApplicationList> {

        return new Promise<ApplicationList>((resolve: (data: ApplicationList, success: HttpSuccess) => void, reject: (error: HttpError|null) => void) => {

            const path = new URI(this.endpoints.clientApplications);
            path.addQuery('limit', limit);

            if (next) {
                path.addQuery('cursor', next);
            }

            if (Array.isArray(withData)) {
                path.addQuery('with[]', withData);
            }

            this.requester.get(path.valueOf()).then((success: HttpSuccess) => {
                resolve(success.data, success);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }

    public find(id: number, withData?: ApplicationExpandedData[]): Promise<Application> {

        return new Promise<Application>((resolve: (data: Application, success: HttpSuccess) => void, reject: (error: HttpError|null) => void) => {

            const path = URI.expand(this.endpoints.clientApplication, {
                id: id,
            });

            if (Array.isArray(withData)) {
                path.addQuery('with[]', withData);
            }

            this.requester.get(path.valueOf()).then((success: HttpSuccess) => {
                resolve(success.data, success);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }

    public needs(id: number): Promise<ApplicationNeed[]> {

        return new Promise<ApplicationNeed[]>((resolve: (data: ApplicationNeed[], success: HttpSuccess) => void, reject: (error: HttpError|null) => void) => {

            const path = URI.expand(this.endpoints.clientApplicationNeeds, {
                id: id,
            }).valueOf();

            this.requester.get(path).then((success: HttpSuccess) => {
                resolve(success.data, success);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }

    public updateNextActionDate(id: number, date: string): Promise<NextActionDate> {

        return new Promise<NextActionDate>((resolve: (data: NextActionDate, success: HttpSuccess) => void, reject: (error: HttpError|null) => void) => {

            const path = URI.expand(this.endpoints.clientUpdateNextActionDate, {
                id: id,
            }).valueOf();

            this.requester.put(path, {date: date}).then((success: HttpSuccess) => {
                resolve(success.data, success);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }
}
