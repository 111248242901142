import {DailyStat, User} from "signature-public-api-sdk";

export type UpdateLanguageAction = (language: PortalLanguage) => void;
export type LoginAction = (username: string, password: string) => void;
export type LogoutAction = () => void;
export type UpdatePasswordAction = (currentPassword: string, newPassword: string) => void;

export type SetDashboardSpanAmount = (amount: number) => void;
export type FetchSummaryAction = (startDate: string, endDate?: string, startDate2?: string, endDate2?: string) => void;
export type FetchOverallStatsAction = (startDate: string, endDate?: string, startDate2?: string, endDate2?: string) => void;
export type FetchCampaignReportsAction = (startDate: string, endDate: string) => void;
export type FetchAllCampaignReportsAction = (startDate: string, endDate: string) => void;
export type FetchAllCampaignDailyStatsAction = (startDate: string, endDate: string) => void;

export type GetApplications = (limit: number, page: number) => void;

export enum PortalLanguage {
    English = 'en',
    Polish = 'pl',
}

import strings from "./Localisation/Localisation";
export const APP_STRINGS: any = strings;

export type LoginResponse = {
    user: User,
}

export enum OverallStatsSpan {
    Months = 'months',
}

export enum SummaryTimeSpan {
    Custom = 'Custom',
    Today = 'today',
    Yesterday = 'yesterday',
    SevenDays = 'SevenDays',
    OneMonth = 'OneMonth',
    ThreeMonths = 'ThreeMonths',
    SixMonths = 'SixMonths',
    Year = 'Year',
}

export enum StatsChartYCol {
    Cost = 'cost',
    Clicks = 'clicks',

    Posts = 'posts',
    PostsAccepted = 'postsAccepted',
    PostsErrors = 'postsErrors',
    PostsRejected = 'postsRejected',
    PostbackFires = 'postbackFires',

    RPT = 'rpt',
    EPC = 'epc',

    TotalResponseTime = 'totalResponseTime',
    AvgResponseTime = 'responseTime',
    PostsWithResponseTime = 'postsWithResponseTime',

    AvgPostCost = 'postCost',
    AvgAcceptCost = 'acceptCost',

    PostsAcceptedPercentage = 'postsAcceptedPercentage',
    ClicksAcceptedPercentage = 'clicksAcceptedPercentage',

    RedirectRate = 'redirectRate',
    SuccessfulRedirects = 'successfulRedirects',
}

export const StatsChartYColLabels = () => {

    return {

        [StatsChartYCol.Cost]: APP_STRINGS.stats.payout,
        [StatsChartYCol.Clicks]: APP_STRINGS.stats.clicks,

        [StatsChartYCol.Posts]: APP_STRINGS.stats.posts,
        [StatsChartYCol.PostsAccepted]: APP_STRINGS.stats.postsAccepted,
        [StatsChartYCol.PostsErrors]: APP_STRINGS.stats.postsErrors,
        [StatsChartYCol.PostsRejected]: APP_STRINGS.stats.postsRejected,
        [StatsChartYCol.PostbackFires]: APP_STRINGS.stats.postbackFires,

        [StatsChartYCol.RPT]: APP_STRINGS.stats.rpt,
        [StatsChartYCol.EPC]: APP_STRINGS.stats.epc,

        [StatsChartYCol.TotalResponseTime]: APP_STRINGS.stats.totalResponseTime,
        [StatsChartYCol.AvgResponseTime]: APP_STRINGS.stats.avgResponseTime,
        [StatsChartYCol.PostsWithResponseTime]: APP_STRINGS.stats.postsWithResponseTime,

        [StatsChartYCol.AvgPostCost]: APP_STRINGS.stats.avgPayoutPost,
        [StatsChartYCol.AvgAcceptCost]: APP_STRINGS.stats.avgPayoutAccept,

        [StatsChartYCol.PostsAcceptedPercentage]: APP_STRINGS.stats.postsAcceptedPercentage,
        [StatsChartYCol.ClicksAcceptedPercentage]: APP_STRINGS.stats.clicksAcceptedPercentage,

        [StatsChartYCol.RedirectRate]: APP_STRINGS.stats.redirectRatePercentage,
        [StatsChartYCol.SuccessfulRedirects]: APP_STRINGS.stats.successfulRedirects,
    };
};

export type ChartData = {
    cost: number,
    cost2: number,

    clicks: number,
    clicks2: number,

    epc: number,
    epc2: number,

    rpt: number,
    rpt2: number,

    postsAccepted: number,
    postsAccepted2: number,

    postbackFires: number,
    postbackFires2: number,

    posts: number,
    posts2: number,

    amount: number,
    amount2: number,

    key?: string,
    date?: string,

    dailyStatCurrent: DailyStat,
    dailyStatPrevious: DailyStat,

    user: User,
}

export enum LoadingState {
    Nothing,
    Pending,
    Error,
}

export type NetworkState = {
    state: LoadingState,
    error: string,
}

export enum BrandName {
    M3 = 'm3',
    Cred4 = 'cred4',
    Loan = 'loan',
}

export type WebpackEnv = {
    NODE_ENV: string,
    brand: {
        style: string,
        name: BrandName,
    },
    showDashboardPage: boolean,
    showApplicationPage: boolean,
    dev: boolean,
}
