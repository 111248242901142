import * as React from "react";
import {Pie, PieChart, ResponsiveContainer, Tooltip, Cell} from "recharts";
import { sortBy } from "lodash";
import {APP_STRINGS, LoadingState, NetworkState, StatsChartYCol, StatsChartYColLabels} from "../../types";
import PerformancePieChartTooltip from "./PerformancePieChartTooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CampaignSummaryStats} from "signature-public-api-sdk";
import {chartCurrentPeriodColour, chartPreviousPeriodColour} from "../../branding";

type Props = {
    allCampaignOverallStats: CampaignSummaryStats[],
    chartsValueKey: StatsChartYCol,
    allCampaignSummaryState: NetworkState,
    lineColours: string[],
}

type State = {
    selectedParentIndex: number,
    selectedParentCampaign: number,
}

const AllowedForChart: StatsChartYCol[] = [
    StatsChartYCol.Cost,

    StatsChartYCol.Clicks,
    StatsChartYCol.Posts,
    StatsChartYCol.PostsAccepted,
    StatsChartYCol.PostsRejected,
    StatsChartYCol.PostsErrors,
    StatsChartYCol.PostsErrors,
    StatsChartYCol.PostbackFires,

    StatsChartYCol.SuccessfulRedirects,

];

class CampaignPerformancePieChart extends React.Component<Props, State> {

    state = {
        selectedParentIndex: null,
        selectedParentCampaign: null,
    };

    render() {
        const args = this.props;
        const pieChartValueKey = 'valueKey';

        if (!args.allCampaignOverallStats)
            return null;

        if (Array.isArray(args.allCampaignOverallStats) && args.allCampaignOverallStats.length === 0)
            return null;

        const chartsValueKey = AllowedForChart.indexOf(args.chartsValueKey) !== -1 ? args.chartsValueKey : StatsChartYCol.Cost;

        const parentCampaigns: CampaignSummaryStats[] = sortBy(args.allCampaignOverallStats.filter((stat: CampaignSummaryStats) => {
            return !stat.isSubCampaign;
        }), (stat: CampaignSummaryStats) => {return stat.campaignId});

        const campaignIds = parentCampaigns.map((stat: CampaignSummaryStats) => {
            return stat.campaignId;
        });

        const parentData: CampaignSummaryStats|any = [];
        const childData: CampaignSummaryStats|any = [];

        for (const campaign of parentCampaigns) {
            parentData.push({
                ...campaign,
                [pieChartValueKey]: Math.abs(campaign[chartsValueKey]),
            });
        }

        const selectedParentIndex = this.state.selectedParentIndex ? this.state.selectedParentIndex : 0;
        const selectedParentCampaign = this.state.selectedParentCampaign ? this.state.selectedParentCampaign : parentData.length > 0 ? parentData[0].campaignId : null;

        // for (const cid of campaignIds) {

            const parent = parentCampaigns.filter((stat: CampaignSummaryStats) => {
                return stat.campaignId === selectedParentCampaign
            })[0];

            const children = args.allCampaignOverallStats.filter((stat: CampaignSummaryStats) => {
                return stat.isSubCampaign === true && stat.campaignId === selectedParentCampaign;
            });

            let total = 0;

            for (const child of children) {
                total += child[chartsValueKey];

                childData.push({
                    ...child,
                    [pieChartValueKey]: Math.abs(child[chartsValueKey]),
                });
            }

            const diff = parent[chartsValueKey] - total;

            if (Math.abs(diff) > 0)
                childData.push({
                    [chartsValueKey]: diff,
                    [pieChartValueKey]: Math.abs(diff),
                });
        // }

        const CustomTooltip = (props: any) => {
            return (
                <PerformancePieChartTooltip
                    {...props}
                    chartsValueKey={chartsValueKey}
                />
            )
        };

        return (
            <div className={'column is-full'}>
                <h3 className={'is-flex'}>
                    {APP_STRINGS.campaigns.performanceSummaryPieChartTitle} ({StatsChartYColLabels()[chartsValueKey]})
                    {this.props.allCampaignSummaryState.state === LoadingState.Pending &&
                    <>
                        &nbsp;<FontAwesomeIcon icon={'spinner'} pulse={true} className={'align-self-center'}/>
                    </>
                    }
                </h3>

                <div className={'columns is-multiline is-mobile'}>
                    <div className="column is-full-mobile is-half-tablet is-half-desktop is-half-widescreen is-half-fullhd">
                        <div className="text-align-center">
                            {APP_STRINGS.campaigns.campaigns}
                        </div>
                        <div className={`campaign-chart`}>
                            <ResponsiveContainer width={'100%'} height={'100%'}>
                                <PieChart>
                                    <Pie
                                        data={parentData}
                                        dataKey={pieChartValueKey}
                                        cx={'50%'}
                                        cy={'50%'}
                                        innerRadius={'10%'}
                                        outerRadius={'70%'}
                                        fill={chartCurrentPeriodColour()}
                                        onClick={(payload: CampaignSummaryStats|any, sliceIndex) => {
                                            this.setState({
                                                selectedParentIndex: sliceIndex,
                                                selectedParentCampaign: payload.campaignId,
                                            });
                                        }}
                                    >
                                        {parentData.map((entry, index) => {
                                            return (
                                                <Cell key={`parent-performance-chart-key-${index}`} fill={args.lineColours[index]}/>
                                            )
                                        })}
                                    </Pie>
                                    {/*<Pie*/}
                                    {/*data={childData}*/}
                                    {/*dataKey={chartsValueKey}*/}
                                    {/*cx={'50%'}*/}
                                    {/*cy={'50%'}*/}
                                    {/*innerRadius={'75%'}*/}
                                    {/*outerRadius={'90%'}*/}
                                    {/*fill={GRAPH_PREVIOUS_LINE_COLOUR}*/}
                                    {/*/>*/}
                                    <Tooltip content={CustomTooltip}/>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                    <div className="column is-full-mobile is-half-tablet is-half-desktop is-half-widescreen is-half-fullhd">
                        <div className="text-align-center">
                            {APP_STRINGS.campaigns.subCampaigns}
                        </div>
                        <div className={`campaign-chart`}>
                            <ResponsiveContainer width={'100%'} height={'100%'}>
                                <PieChart>
                                    <Pie
                                        data={childData}
                                        dataKey={pieChartValueKey}
                                        cx={'50%'}
                                        cy={'50%'}
                                        innerRadius={'10%'}
                                        outerRadius={'70%'}
                                        fill={chartPreviousPeriodColour()}
                                    >

                                        {childData.map((entry, index) => {
                                            return (
                                                <Cell key={`child-performance-chart-key-${index}`} fill={args.lineColours[selectedParentIndex]}/>
                                            )
                                        })}
                                    </Pie>
                                    <Tooltip content={CustomTooltip}/>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private legend = () => {
        return (

            <div className={'graph-legend is-flex'}>
                <div
                    className={'key'}
                    style={{
                        color: chartCurrentPeriodColour(),
                        borderColor: chartCurrentPeriodColour(),
                    }}
                >
                    {APP_STRINGS.campaigns.campaigns}
                </div>
                <div
                    className={'key'}
                    style={{
                        color: chartPreviousPeriodColour(),
                        borderColor: chartPreviousPeriodColour(),
                    }}
                >
                    {APP_STRINGS.campaigns.subCampaigns}
                </div>
            </div>
        );
    }
}

export default CampaignPerformancePieChart;
