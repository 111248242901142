export type HttpHeaders = {
    [key: string]: string;
}

export type HttpSuccess = {
    data: any,
    statusCode: number,
    headers: HttpHeaders,
}

export type HttpError = {
    error: Error,
    response: any,
    statusCode: number
}

export type LoanApiEndpoints = {
    login: string,
    logout: string,
    me: string,
    updatePassword: string,
    timeComparisonStats: string,
    overallComparisonStats: string,
    overallComparisonStatsSpan: string,
    campaignsSummaryStats: string,
    allCampaignsSummaryStats: string,
    allCampaignsDailyStats: string,
    affiliateApplicationList: string,

    clientCreateUser: string,
    clientMe: string,
    clientUpdatePassword: string,
    clientApplications: string,
    clientApplication: string,
    clientApplicationNeeds: string,
    clientUpdateNextActionDate: string,

    clientRequestPasswordResetEmail: string,
    clientValidatePasswordResetToken: string,
    clientPerformPasswordReset: string,

    clientRequestEmailVerificationEmail: string,
    clientValidateEmailVerificationToken: string,
    clientPerformEmailVerification: string,

    clientValidateRegistrationToken: string,

    viewFile: string,
    downloadFile: string,
    uploadFile: string,
};

export type TimePeriodPerformanceStat = {
    posts: number,
    postsAccepted: number,
    cost: number,
    clicks: number,
    rpt: number,
    epc: number,
    postbackFires: number

    //YYYY-MM-DD
    from: string,
    to: string
};

export type DailyStat = {
    //YYYY-MM-DD
    date: string,
    posts: number,
    postsAccepted: number,
    cost: number,
    clicks: number,
    rpt: number,
    epc: number,
    postbackFires: number,
}

export type User = {
    id?: number,
    title?: string,
    name?: string,
    middleName?: string,
    lastName?: string,
    email?: string,
    mobileTel?: string,
    homeTel?: string,
    contactPreference?: ContactPreference|null,
    emailVerified?: boolean,

    //can be sent when registering
    password?: string,


    broker?: Broker|null,
    canAccessAffiliatePortal?: boolean,
    canAccessClientPortal?: boolean,
}

export type ContactPreference = {
    id: number,
    flags: {
        allowMobile: boolean,
        allowSms: boolean,
        allowEmail: boolean,
        allowMarketingEmails: boolean,
        allowMarketingSms: boolean,
    },
    primaryContactMethod: ContactPreferencePrimaryType,
    contactFrom: string,
    contactTo: string,
}

export enum ContactPreferencePrimaryType {
    Mobile = 'mobile',
    SMS = 'sms',
    Email = 'email',
}

export enum PasswordResetDeliveryMethod {
    SMS = 'sms',
    Email = 'email',
}

export type Broker = {
    name: string,
    bucketUsedCount: number,
}

export enum ApplicationExpandedData {
    Documents = 'documents',
    NextActionDates = 'nextActionDates',
    Applicants = 'applicants',
}