import {LoanApiEndpoints} from "./Types";

export const DEFAULT_API_ENDPOINTS: LoanApiEndpoints = {
    login: '/api-login',
    logout: '/api-logout',

    me: '/portal2/v1/users/me',
    updatePassword: '/portal2/v1/users/update-password',

    timeComparisonStats: '/portal2/v1/dashboard/time-period-comparison-stats',
    overallComparisonStats: '/portal2/v1/dashboard/time-period-overall-stats',
    overallComparisonStatsSpan: '/portal2/v1/dashboard/time-period-overall-stats-span',

    campaignsSummaryStats: '/portal2/v1/campaigns/summary-stats',
    allCampaignsSummaryStats: '/portal2/v1/campaigns/all-summary-stats',
    allCampaignsDailyStats: '/portal2/v1/campaigns/all-daily-stats',

    affiliateApplicationList: '/portal2/v1/applications/list',

    clientCreateUser: '/client-portal/v1/users/create',
    clientMe: '/client-portal/v1/users/me',
    clientUpdatePassword: '/client-portal/v1/users/update-password',
    clientApplications: '/client-portal/v1/applications/list',
    clientApplication: '/client-portal/v1/applications/{id}',
    clientApplicationNeeds: '/client-portal/v1/applications/{id}/needs',
    clientUpdateNextActionDate: '/client-portal/v1/next-action-dates/{id}',

    clientRequestPasswordResetEmail: '/client-portal/v1/anon/emails/request-password-recovery',
    clientValidatePasswordResetToken: '/client-portal/v1/anon/reset-password/{token}/validate',
    clientPerformPasswordReset: '/client-portal/v1/anon/reset-password/{token}',

    clientRequestEmailVerificationEmail: '/client-portal/v1/anon/emails/request-email-verification',
    clientValidateEmailVerificationToken: '/client-portal/v1/anon/email-verification/{token}/validate',
    clientPerformEmailVerification: '/client-portal/v1/anon/email-verification/{token}',

    clientValidateRegistrationToken: '/client-portal/v1/anon/registration/{token}/validate',

    viewFile: '/client-portal/v1/files/{id}/view',
    downloadFile: '/client-portal/v1/files/{id}/download',
    uploadFile: '/client-portal/v1/documents/{id}/upload',

};