import {IHttpRequester} from "../../Interfaces/IHttpRequester";
import {HttpError, HttpSuccess, LoanApiEndpoints} from "../../Types";
import { AffiliateApplication, PaginatedResults } from "../../ResponseTypes";
import * as URI from "urijs";

export class AffiliateApplications {

    private endpoints: LoanApiEndpoints;
    private requester: IHttpRequester;

    constructor(endpoints: LoanApiEndpoints, requester: IHttpRequester) {
        this.endpoints = endpoints;
        this.requester = requester;
    }

    /**
     * Get a list of applications
     */
    public list(limit: number, page: number): Promise<PaginatedResults<AffiliateApplication>> {

        return new Promise<PaginatedResults<AffiliateApplication>>((resolve: (data: PaginatedResults<AffiliateApplication>) => void, reject: (error: HttpError|null) => void) => {

            const url = new URI(this.endpoints.affiliateApplicationList);

            if (limit)
                url.addQuery('limit', limit);

            if (page)
                url.addQuery('page', page);

            this.requester.get(url.valueOf()).then((success: HttpSuccess) => {
                resolve(success.data);
            }).catch((error: HttpError) => {
                reject(error);
            });
        });
    }
}
