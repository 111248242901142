import * as numeral from "numeral"
require('numeral/locales/en-gb.js');
require('numeral/locales/pl.js');
import { assign } from "lodash"

export const DEFAULT_LANGUAGE = 'en_GB';
export const SYSTEM_LOCALE = document.body.dataset.systemLocale ? document.body.dataset.systemLocale : DEFAULT_LANGUAGE;

// Symfony uses en_GB, pl_PL whilst numeral expects en-gb or pl
let numeralLocal = SYSTEM_LOCALE.replace('_', '-').toLowerCase();
if(numeralLocal.split('-')[0] == numeralLocal.split('-')[1]) {
    numeralLocal = numeralLocal.split('-')[0]
}
numeral.locale(numeralLocal);

// override Polish language to include zł
if(numeralLocal == 'pl' ) {
    assign(numeral.localeData(), {delimiters: { thousands: ',', decimal: '.' }, currency: {symbol: 'zł', position: 'postfix'}});
}

const numeralCurrency = numeral.localeData().currency as any;
export const CURRENCY_POSITION = numeralCurrency.position ? numeralCurrency.position : 'prefix';
export const CURRENCY_SYMBOL = numeralCurrency.symbol;

export const CURRENCY_FORMAT = ( CURRENCY_POSITION == 'postfix' ) ?  '0,0.00 $' : '$0,0.00';
export const CURRENCY_FORMAT_SHORT = ( CURRENCY_POSITION == 'postfix' ) ?  '0,0 $' : '$0,0.00';

export const MONEY_FORMAT = '0,0.00 ';