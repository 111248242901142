import * as React from "react";
import {default as Navigation, NavigationComponentProps} from "./Navigation";
import {NetworkState, PortalLanguage, UpdateLanguageAction} from "../../types";

type NavigationWrapperProps = NavigationComponentProps & {
    children: JSX.Element,
    logoutState: NetworkState,

    language: PortalLanguage,
    updateLanguageAction: UpdateLanguageAction,
}

const NavigationWrapper: React.ComponentType<NavigationWrapperProps> = (args: NavigationWrapperProps) => (
    <div id="nav-container">
        <Navigation
            language={args.language}
            updateLanguageAction={args.updateLanguageAction}
            logoutState={args.logoutState}
            logout={args.logout}
        />
        <main>
            {args.children}
        </main>
    </div>
);

export default NavigationWrapper;